import { memo } from "react";
import styled from "styled-components";

interface Props {
  maxHeight?: number;
}

const setModalHeight = (maxHeight: number) => `
  overflow: hidden;
  max-height: ${maxHeight}rem;
`;

const TooltipContainer = styled.span`
  position: relative;

  > span:first-child {
    position: absolute;
    z-index: 10;
    top: 1.5rem;
    width: 50vw;
    max-width: 600px;
    padding: 0.4rem;
    border: 1px solid #12d37a;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0 4px 10px #9faba494;
    color: #000;
    cursor: default;
    font-size: 1rem;
    ${({ maxHeight }: Props) => (maxHeight ? setModalHeight(maxHeight) : null)};
    text-align: left;
    visibility: hidden;

    @media (max-width: 500px) {
      display: none;
    }
  }

  @media (min-width: 500px) {
    :hover > span,
    :focus > span {
      visibility: visible;
    }
  }
`;

export default memo(TooltipContainer);
