import styled from "styled-components";

export const SliderContainer = styled.div`
  width: 100%;
  text-align: center;

  div {
    display: inline-block;
    width: 80%;
  }
`;

export const Range = styled.input`
  width: 100%;
`;

export const Datalist = styled.datalist`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  font-size: min(1.5vw, 1rem);
  writing-mode: vertical-lr;
`;

export const Reset = styled.input`
  border: none;
`;
