import styled from "styled-components";

const Img = styled.img`
  width: 30px;
  margin-right: 0.4rem;
`;

interface Props {
  id: string;
  title: string;
}

const YoutubeThumbnail = ({ id, title }: Props): JSX.Element => (
  <Img src={`https://i.ytimg.com/vi/${id}/hqdefault.jpg`} alt={title} />
);

export default YoutubeThumbnail;
