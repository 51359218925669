import styled from "styled-components";

import Emoji from "../../Emoji/Emoji";
import Giphy from "../../Giphy/Giphy";

const Section = styled.section`
  display: flex;
  min-height: 90vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.p`
  margin: 1rem;
  color: white;
  font-size: 3rem;
`;

const Error404 = (): JSX.Element => (
  <Section>
    <Giphy term="error" />

    <Emoji>😭</Emoji>
    <Title>Page not found.</Title>
  </Section>
);

export default Error404;
