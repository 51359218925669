interface Props {
  gif: string;
  webp: string;
}

const Webp = ({ gif, webp }: Props): JSX.Element => (
  <picture>
    <source type="image/webp" srcSet={gif} />
    <img src={webp} alt="Error" />
  </picture>
);

export default Webp;
