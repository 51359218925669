import throttle from "lodash/throttle";
import { useEffect, useState } from "react";

function useScrollVelocity(): number {
  const [velocity, setVelocity] = useState(0);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [lastTimestamp, setLastTimestamp] = useState(0);

  const calculateScrollVelocity = throttle(() => {
    const currentScrollY = window.scrollY;
    const currentTimestamp = performance.now();
    const timeDelta = currentTimestamp - lastTimestamp;
    const distance = currentScrollY - lastScrollY;
    const newVelocity = distance / timeDelta;

    setVelocity(newVelocity);
    setLastScrollY(currentScrollY);
    setLastTimestamp(currentTimestamp);
  }, 100);

  useEffect(() => {
    const animationFrameId = requestAnimationFrame(calculateScrollVelocity);
    return () => cancelAnimationFrame(animationFrameId);
  }, [lastScrollY, lastTimestamp, calculateScrollVelocity]);

  return Number(velocity.toFixed(4));
}

export default useScrollVelocity;
