import times from "lodash/times";
import { useCallback, useState } from "react";

import pickAndPipe from "../../../utils/pickAndPipe";
import {
  Datalist,
  Range,
  Reset,
  SliderContainer,
} from "../BaseSlider/BaseSlider";

interface Props {
  min: number;
  max: number;
}

const FontSizeSlider = ({ min, max }: Props): JSX.Element => {
  const [list] = useState<string[]>(
    times(max).map((size) => `font-size-${size + 1}`)
  );

  const reset = useCallback(() => {
    document.querySelector("html")?.classList.remove(...list);
  }, [list]);

  const setFontSize = (size: number) => {
    reset();
    document.querySelector("html")?.classList.add(`font-size-${size}`);
  };

  return (
    <SliderContainer>
      <div>
        <Range
          type="range"
          step="1"
          min={min}
          max={max}
          list="markers"
          defaultValue={1}
          onChange={pickAndPipe("target.value", Number, setFontSize)}
        />

        <Datalist id="markers">
          {times(max - min + 1).map((value) => (
            <option
              key={value}
              value={min + value}
              label={`${0.5 + (min + value) / 2}rem`}
            />
          ))}
        </Datalist>
      </div>
      <Reset type="reset" onClick={reset} value="X" />
    </SliderContainer>
  );
};

export default FontSizeSlider;
