import noop from "lodash/noop";
import { memo } from "react";
import YouTube, { YouTubePlayer, YouTubeProps } from "react-youtube";

import { sendYoutubeAnalytics } from "../../utils/analytics";

interface Props {
  id: string;
  onEnd?: YouTubeProps["onEnd"];
  autoplay?: boolean;
  getPlayerControls?: (event: YouTubePlayer) => void;
}

const YoutubePlayer: React.FC<Props> = ({
  id,
  onEnd,
  autoplay,
  getPlayerControls,
}: Props) => (
  <YouTube
    videoId={id}
    onEnd={(event) => {
      sendYoutubeAnalytics({
        action: "Video end",
        label: id,
      });
      onEnd?.(event);
    }}
    onPlay={() => {
      sendYoutubeAnalytics({
        action: "Video play",
        label: id,
      });
    }}
    onReady={(event) => {
      getPlayerControls?.(event);
    }}
    opts={{
      playerVars: {
        autoplay: autoplay ? 1 : 0,
      },
    }}
  />
);

YoutubePlayer.defaultProps = {
  onEnd: noop,
  getPlayerControls: noop,
  autoplay: false,
};

export default memo(YoutubePlayer);
