import styled from "styled-components";

import SectionCenter from "../SectionCenter/SectionCenter";

const Section = styled(SectionCenter)`
  height: 90vh;
`;

const SuspenseFallback = (): JSX.Element => (
  <Section>Loading something...</Section>
);

export default SuspenseFallback;
