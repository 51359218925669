import debounce from "lodash/debounce";
import { useEffect, useState } from "react";

import useCursorCoordinates from "../../hooks/cursorcoordinates";

const CursorDirection = (): JSX.Element | null => {
  const coordinates = useCursorCoordinates(debounce);
  const [left, setLeft] = useState<boolean | null>(null);
  const [up, setUp] = useState<boolean | null>(null);

  useEffect(() => {
    const { prev, current } = coordinates;

    const x = prev.x - current.x;
    const y = prev.y - current.y;

    setLeft(x > 0);
    setUp(y > 0);
  }, [coordinates]);

  if (up === null && left === null) {
    return null;
  }

  return (
    <>
      {up && (left ? <>&#8598;</> : <>&#8599;</>)}
      {!up && (left ? <>&#8601;</> : <>&#8600;</>)}
      {up ? "up" : "down"}
      {left ? "left" : "right"}
    </>
  );
};

export default CursorDirection;
