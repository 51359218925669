/* eslint-disable no-console */

import shuffle from "lodash/shuffle";

const colorconsole = (): void => {
  const commonStyles = "padding: 3px; font-family: monospace; font-size: 20px;";
  const colors = shuffle(["f5a958", "f558ef", "7844f3", "f344e5"]);
  const textColors = shuffle(["white", "black", "green", "purple"]);

  console.log(
    "%cJ%cu%ch%cQ",
    `background: #${colors[0]}; color: ${textColors[0]}; ${commonStyles}`,
    `background: #${colors[1]}; color: ${textColors[1]}; ${commonStyles}`,
    `background: #${colors[2]}; color: ${textColors[2]}; ${commonStyles}`,
    `background: #${colors[3]}; color: ${textColors[3]}; ${commonStyles}`
  );
};

export default colorconsole;
