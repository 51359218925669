import { useEffect } from "react";

import useLocationRoute from "./locationroute";

interface LocationRoute {
  title: string;
}

const useChangeTitle = () => {
  const locationRoute: LocationRoute | undefined = useLocationRoute();

  useEffect(() => {
    if (locationRoute) {
      document.title = `JuhQ.fi - ${locationRoute.title}`;
    } else {
      document.title = "JuhQ.fi";
    }
  }, [locationRoute]);
};

export default useChangeTitle;
