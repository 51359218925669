const VisitorCounter = (): JSX.Element => (
  <img
    src={`https://hitwebcounter.com/counter/counter.php?page=${process.env.REACT_APP_VISITOR_COUNTER_ID}&style=0009&nbdigits=5&type=page&initCount=0`}
    alt="web counter"
    width={115}
    height={32}
  />
);

export default VisitorCounter;
