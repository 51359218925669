/* stylelint-disable a11y/media-prefers-reduced-motion */
/* stylelint-disable plugin/no-low-performance-animation-properties */

import throttle from "lodash/throttle";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.2rem;
  background-color: rgb(131 111 111 / 76%);
`;

interface BarProp {
  scrollPercentage: number;
}

const Bar = styled.div`
  width: ${({ scrollPercentage }: BarProp) => `${scrollPercentage}%`};
  height: 100%;
  background-color: rgb(255 0 247 / 82%);
  transition: width 0.2s ease-in-out;
`;

const ScrollIndicator = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const eventFunction = useCallback(() => {
    const { scrollHeight, clientHeight, scrollTop } = document.documentElement;
    const percentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
    setScrollPercentage(percentage);
  }, []);

  useEffect(() => {
    const listener = throttle(eventFunction, 100);

    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, [eventFunction]);

  return (
    <Container
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={scrollPercentage}
    >
      <Bar data-testid="blue-bar" scrollPercentage={scrollPercentage} />
    </Container>
  );
};

export default ScrollIndicator;
