import times from "lodash/times";
import { useEffect, useState } from "react";
import styled from "styled-components";

import routes, { Route, gameRoutes } from "../Router/routes";

const Container = styled.div`
  text-align: center;
`;

const Button = styled.button`
  width: 4rem;
  height: 4rem;
  border-color: #f9dadf;
  margin: 0.1rem;
  background: #ffe5e2;
  border-radius: 0.25rem;
  color: #df4141;
  font-size: 2rem;
  font-weight: bold;

  :active {
    border-color: #dadaf9;
    background: #e2ffe6;
    color: #26834b;
  }
`;

const SuggestionContainer = styled.div`
  overflow: auto;
  max-height: 8rem;
  margin-top: 1rem;
`;

export interface NumPadProps {
  onMatch: (path: string) => void;
}

const listOfRoutes = routes().concat(
  gameRoutes.map((r) => ({ ...r, path: `games/${r.path}` }))
);

const NumPad = ({ onMatch }: NumPadProps): JSX.Element => {
  let value = 1;
  const [keycode, setKeycode] = useState<string>("");
  const [path, setPath] = useState<null | string>(null);
  const [partials, setPartials] = useState<Route[]>([]);

  // eslint-disable-next-line no-plusplus
  const nextValue = () => value++;

  useEffect(() => {
    const match = listOfRoutes.find(({ numpadCode }) => numpadCode === keycode);
    if (match) {
      setPath(`/${match.path}`);
    }
  }, [keycode]);

  useEffect(() => {
    if (keycode.length) {
      const match = listOfRoutes.filter(({ numpadCode }) =>
        numpadCode.startsWith(keycode)
      );

      setPartials(match);
    } else {
      setPartials([]);
    }
  }, [keycode]);

  useEffect(() => {
    if (path !== null) {
      onMatch(path);
    }
  }, [onMatch, path]);

  return (
    <Container>
      {times(3, (i) => (
        <span key={i}>
          {times(3, (j) => {
            const number = nextValue();

            return (
              <Button
                type="button"
                key={`${i}-${j}`}
                onClick={() => setKeycode(`${keycode}${number}`)}
              >
                {number}
              </Button>
            );
          })}
          <br />
        </span>
      ))}

      <Button type="button">#</Button>
      <Button type="button" onClick={() => setKeycode(`${keycode}0`)}>
        0
      </Button>
      <Button type="button" onClick={() => setKeycode("")}>
        ←
      </Button>

      <SuggestionContainer>
        {partials.map(({ title, numpadCode }) => (
          <div key={numpadCode}>
            {numpadCode}: {title}
          </div>
        ))}
      </SuggestionContainer>
    </Container>
  );
};

export default NumPad;
