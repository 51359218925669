/* stylelint-disable plugin/no-low-performance-animation-properties */

import styled from "styled-components";

export default styled.span`
  animation: blinking-text 1.2s infinite;

  @media screen and (prefers-reduced-motion) {
    animation: none;
  }

  @keyframes blinking-text {
    0%,
    49%,
    100% {
      visibility: inherit;
    }

    60%,
    99% {
      visibility: hidden;
    }
  }
`;
