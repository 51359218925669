/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable declaration-colon-newline-after */
/* stylelint-disable plugin/no-low-performance-animation-properties */

import styled from "styled-components";

export default styled.h1`
  animation: shine 2s linear infinite;
  background: linear-gradient(
    to right,
    #b491cf 0%,
    #13cf7f 25%,
    #d8dc94 50%,
    #b491cf 100%
  );
  -webkit-background-clip: text;
  background-size: 200% auto;
  -webkit-text-fill-color: transparent;

  @media screen and (prefers-reduced-motion) {
    animation: none;
  }

  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
`;
