import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import NumPadModal from "../NumPadModal/NumPadModal";

const Button = styled.button`
  padding: 0.4rem 0.5rem;
  border-color: #4165ab;
  margin: 0.1rem;
  background: #2d4676;
  background: linear-gradient(to top, #2d4676 0%, #4e5e8d 100%);
  border-radius: 0.25rem;
  color: #a7b8d9;
  font-weight: bold;
`;

const HeaderMenuNumPad = (): JSX.Element => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);
  const [path, setPath] = useState<null | string>(null);

  useEffect(() => {
    if (path !== null) {
      setVisible(false);
    }
  }, [path]);

  useEffect(() => {
    if (!visible && path) {
      navigate(path);
      setPath(null);
    }
  }, [visible, path, navigate]);

  return (
    <>
      <Button
        onClick={() => {
          setVisible(true);
          setPath(null);
        }}
        type="button"
      >
        #
      </Button>

      {visible && (
        <NumPadModal onMatch={setPath} onClose={() => setVisible(false)} />
      )}
    </>
  );
};

export default HeaderMenuNumPad;
