import throttle from "lodash/throttle";
import { useEffect, useState } from "react";

interface Coordinates {
  prev: number;
  current: number;
}

const useScroll = (): Coordinates => {
  const [coordinates, setCoordinates] = useState<Coordinates>({
    prev: -1,
    current: 0,
  });

  const eventFunction = () => {
    const { scrollY: y } = window;

    setCoordinates(({ prev, current }) => ({
      prev: prev === -1 ? y : current,
      current: y,
    }));
  };

  useEffect(() => {
    const listener = throttle(eventFunction, 100);

    document.addEventListener("scroll", listener);
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, []);

  return coordinates;
};

export default useScroll;
