import { useEffect } from "react";

import useAnalytics from "../../../hooks/analytics";
import useChangeTitle from "../../../hooks/changetitle";
import colorconsole from "../../../other/colorconsole";
import cursor from "../../../other/cursor";

const RouterHooks = (): null => {
  useAnalytics();
  useChangeTitle();

  useEffect(() => {
    colorconsole();
    return cursor();
  }, []);

  return null;
};

export default RouterHooks;
