import differenceInDays from "date-fns/differenceInDays";
import format from "date-fns/format";
import formatDistance from "date-fns/formatDistance";
import { useEffect, useState } from "react";

const dateFormat = "d.M.yyy HH:MM:ss";
const timestamp = process.env.REACT_APP_TIMESTAMP;
const gitHash = process.env.REACT_APP_GIT_HASH;
const gitCommits = Number(process.env.REACT_APP_GIT_COMMITS);
const time = Number(timestamp) * 1000;
const date = format(time, dateFormat);

// https://github.com/JuhQ/juhq.fi/commit/f680bb84c4ca0bedabc6c605c134515476627973
const firstCommit = new Date("06/02/2021 15:02:38");
const firstCommitFormat = format(firstCommit, dateFormat);

const getDistance = (from: number | Date): string =>
  formatDistance(from, new Date(), { addSuffix: true });

const getDaysSinceFirstCommit = (): number =>
  differenceInDays(new Date(), firstCommit);

const BuildInfo = (): JSX.Element => {
  const [distanceBuild, setDistanceBuild] = useState<string>(getDistance(time));
  const [distanceCommit, setDistanceCommit] = useState<string>(
    getDistance(firstCommit)
  );
  const [daysSinceFirstCommit, setDaysSinceFirstCommit] = useState<number>(
    getDaysSinceFirstCommit
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setDistanceBuild(getDistance(time));
      setDistanceCommit(getDistance(firstCommit));
      setDaysSinceFirstCommit(getDaysSinceFirstCommit());
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="pixel-font">
      <span title={gitHash}>
        Build date: {date}, {distanceBuild}
      </span>
      <br />
      <span title="f680bb84c4ca0bedabc6c605c134515476627973">
        First commit: {firstCommitFormat}, {distanceCommit},{" "}
        <span title={`${gitCommits / daysSinceFirstCommit} commits per day`}>
          {gitCommits} commits since
        </span>
      </span>
    </div>
  );
};

export default BuildInfo;
