import styled from "styled-components";

import ExternalLink from "../ExternalLink/ExternalLink";
import ShieldIo from "../ShieldIo/ShieldIo";

const Div = styled.div`
  display: flex;
  justify-content: space-around;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const ButtonRow = (): JSX.Element => (
  <Div>
    <ShieldIo
      link="https://github.com/JuhQ/juhqfi_issues/issues"
      alt="Issue tracker"
      service="github/issues/juhq/juhqfi_issues"
    />
    <ExternalLink to="https://t.me/+62rw4hIv9C5lODlk" favicon={false}>
      <img
        src="https://img.shields.io/badge/-Telegram-red?color=white&logo=telegram&logoColor=blue"
        alt="Telegram"
      />
    </ExternalLink>
    <ExternalLink
      to="https://gitter.im/JuhQ-fi-support-group/community"
      favicon={false}
    >
      <img
        src="https://badges.gitter.im/JuhQ-fi-support-group/community.svg"
        alt="gitter"
      />
    </ExternalLink>
    <ShieldIo
      link="https://discord.gg/KNZQR78j8J"
      alt="Discord"
      service="discord/1063828339514675250?logo=discord"
    />
    <ShieldIo
      link="https://www.youtube.com/channel/UCnZrO_AwUnhzh9p2QMtbkoA"
      alt="YouTube views"
      service="youtube/channel/views/UCnZrO_AwUnhzh9p2QMtbkoA?style=social"
    />
    <ShieldIo
      link="https://www.youtube.com/channel/UCnZrO_AwUnhzh9p2QMtbkoA"
      alt="YouTube subscribers"
      service="youtube/channel/subscribers/UCnZrO_AwUnhzh9p2QMtbkoA?style=social"
    />
    <ShieldIo
      link="https://twitter.com/juha_tauriainen"
      alt="Twitter"
      service="twitter/follow/juha_tauriainen?style=social"
    />
    <ShieldIo
      link="https://twitter.com/JuhQ_fi"
      alt="Twitter"
      service="twitter/follow/JuhQ_fi?style=social"
    />
    <ShieldIo
      link="https://github.com/JuhQ"
      alt="GitHub followers"
      service="github/followers/juhq?style=social"
    />
    <ShieldIo
      link="https://ohai.social/@juhq"
      alt="Mastodon"
      service="mastodon/follow/109688129366189687?domain=https%3A%2F%2Fohai.social&logo=mastodon"
    />
    <ShieldIo
      link="https://join.slack.com/t/juhqworkspace/shared_invite/zt-20az4l3hd-_aGrFC2wcOr0XoFwWP8IzQ"
      alt="slack"
      service="badge/slack-8A2BE2"
    />
    <ShieldIo
      link="https://www.reddit.com/user/juhqbest"
      alt="Reddit karma"
      service="reddit/user-karma/combined/juhqbest"
    />
    <ShieldIo
      link="https://matrix.to/#/#juhq-support-group:matrix.org"
      alt="Matrix"
      service="matrix/juhq-support-group%3Amatrix.org"
    />
  </Div>
);

export default ButtonRow;
