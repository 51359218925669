/* eslint-disable no-plusplus */

import { useEffect, useState } from "react";

import useLocationRoute from "../../hooks/locationroute";

const AnimatePageTitle = (): null => {
  const [animate, setAnimate] = useState<boolean>(false);
  const locationRoute = useLocationRoute();

  const titleToBeFound = "Radio";

  useEffect(() => {
    setAnimate(locationRoute?.title === titleToBeFound);
  }, [locationRoute]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (animate) {
      const wave = '_.~"~.'.repeat(5);
      const title = `JuhQ.fi - ${titleToBeFound} - ${wave}_ `.repeat(3);

      let i = 0;
      interval = setInterval(() => {
        if (title.length - i <= 0) {
          i = 0;
        }

        const newTitle = title.slice(i, title.length) + title.slice(0, i);
        document.title = newTitle;

        i++;
      }, 100);
    }

    return () => {
      clearInterval(interval);
    };
  }, [animate]);

  return null;
};

export default AnimatePageTitle;
