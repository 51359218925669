import isFriday from "date-fns/isFriday";
import shuffle from "lodash/shuffle";
import { useEffect, useState } from "react";
import { YouTubePlayer } from "react-youtube";
import styled from "styled-components";

import { Song, allSongs, perjantaiPlayList } from "../Playlist/playlist-data";
import YoutubePlayer from "../YoutubePlayer/YoutubePlayer";
import YoutubeThumbnail from "../YoutubeThumbnail/YoutubeThumbnail";

const HiddenSection = styled.section`
  display: none;
`;

const Button = styled.button`
  border: none;
`;

const isItFriday = () => isFriday(new Date());

const FloatingRadio = (): JSX.Element | null => {
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [play, setPlay] = useState<boolean>(false);
  const [fridayPlaylist, setFridayPlaylist] = useState<boolean>(false);
  const [showFridayPlaylist, setShowFridayPlaylist] = useState<boolean>(false);
  const [pause, setPause] = useState<boolean>(true);
  const [index, setIndex] = useState<number>(0);
  const [current, setCurrent] = useState<Song | null>(null);
  const [playlist, setPlaylist] = useState<Song[] | null>(null);
  const [playerControls, setPlayerControls] = useState<YouTubePlayer | null>(
    null
  );

  useEffect(() => {
    setPlaylist(shuffle(allSongs));
    setShowFridayPlaylist(isItFriday());
  }, []);

  useEffect(() => {
    if (fridayPlaylist) {
      setPlaylist(perjantaiPlayList);
    }
  }, [fridayPlaylist]);

  useEffect(() => {
    if (playlist) {
      setCurrent(playlist[index]);
    }
  }, [playlist, index]);

  if (!current || !playlist) {
    return null;
  }

  const next = () => {
    const newIndex = index + 1 >= playlist.length ? 0 : index + 1;
    setIndex(newIndex);
    setCurrent(playlist[newIndex]);
  };

  const shouldPlay = play && !pause;

  const startPlayer = () => {
    setInitialRender(false);
    setPlay(true);
    setPause(false);
    playerControls?.target.playVideo();
  };

  return (
    <>
      <HiddenSection>
        {!initialRender && (
          <YoutubePlayer
            id={current.id}
            autoplay={shouldPlay}
            onEnd={next}
            getPlayerControls={setPlayerControls}
          />
        )}
      </HiddenSection>
      <YoutubeThumbnail id={current.id} title={current.title} />
      {!shouldPlay && (
        <Button type="button" onClick={startPlayer} title="Play">
          &#x23F5;
        </Button>
      )}
      {shouldPlay && (
        <Button
          type="button"
          onClick={() => {
            setPause(!pause);
            playerControls?.target.pauseVideo();
          }}
          title="Pause"
        >
          &#x23F8;
        </Button>
      )}
      <Button type="button" title="Next" onClick={next}>
        &#x23ED;
      </Button>
      {showFridayPlaylist && (
        <Button
          type="button"
          title="Friday playlist"
          onClick={() => {
            setFridayPlaylist(true);
            startPlayer();
          }}
        >
          😜
        </Button>
      )}
    </>
  );
};

export default FloatingRadio;
