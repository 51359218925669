/* eslint-disable react/no-array-index-key */

import { useEffect, useState } from "react";
import styled from "styled-components";

interface FlowerProps {
  x: number;
  y: number;
}

interface GrassStrainProps {
  x: number;
}

const Div = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  max-width: 100%;
  height: 14rem;
`;

const FlowerContainer = styled.g`
  animation: spin 60s linear infinite;
  transform-box: fill-box;
  transform-origin: center;

  @media screen and (prefers-reduced-motion) {
    animation: none;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const GrassStrain = ({ x }: GrassStrainProps) => {
  const height = Math.random() * 100 + 50; // random height between 50 and 150
  const curve = Math.random() * 30 - 15; // random curve between -15 and 15
  const d = `M1.5,150 C1.5,${150 - height / 2},${curve},${
    150 - height / 2
  } 1.5,${150 - height}`;

  return (
    <svg
      height="150"
      width="3"
      style={{ position: "absolute", left: x, bottom: 0 }}
    >
      <path d={d} stroke="green" strokeWidth="3" fill="transparent" />
    </svg>
  );
};

const Grass: React.FC = () => {
  const grassStrains = Array.from(
    { length: window.innerWidth / 3 },
    (_, i) => i * 3
  );
  return (
    <div>
      {grassStrains.map((x) => (
        <GrassStrain key={x} x={x} />
      ))}
    </div>
  );
};

const colors = ["yellow", "red", "orange", "pink", "purple"];

const Flower = ({ x, y }: FlowerProps) => (
  <svg
    height="210"
    width="60"
    style={{ position: "absolute", left: x, bottom: y }}
  >
    <line x1="30" y1="210" x2="30" y2="30" stroke="green" strokeWidth="3" />
    <FlowerContainer>
      {colors.map((color, i) => (
        <path
          key={i}
          d="M30 10 Q40 20 30 30 Q20 20 30 10"
          fill={color}
          transform={`rotate(${i * (360 / colors.length)} 30 30)`}
        />
      ))}
    </FlowerContainer>
  </svg>
);

interface FlowerPosition {
  x: number;
  y: number;
}

const Garden = () => {
  const [flowers, setFlowers] = useState<FlowerPosition[]>([]);

  useEffect(() => {
    const newFlowers = Array.from(
      { length: Math.random() * 20 },
      (): FlowerPosition => ({
        x: Math.random() * window.innerWidth,
        y: Math.random() * 35,
      })
    );
    setFlowers(newFlowers);
  }, []);

  return (
    <Div>
      {flowers.map((flower, index) => (
        <Flower key={index} x={flower.x} y={flower.y} />
      ))}
      <Grass />
    </Div>
  );
};

export default Garden;
