import "normalize-opentype.css/normalize-opentype.css";
import "normalize.css";
import "sanitize.css";
import "sanitize.css/forms.css";
import "sanitize.css/typography.css";

import "./index.css";

import { StrictMode } from "react";
import ReactDOM from "react-dom";

import Router from "./components/Router/Router";
import reportWebVitals from "./reportWebVitals";
import { initializeSentry, sendWebVitalsToAnalytics } from "./utils/analytics";

initializeSentry();

ReactDOM.render(
  <StrictMode>
    <Router />
  </StrictMode>,
  document.getElementById("root")
);

reportWebVitals(sendWebVitalsToAnalytics);
