/* stylelint-disable declaration-colon-newline-after */
/* stylelint-disable plugin/no-low-performance-animation-properties */

import styled from "styled-components";

import juhq from "../../../images/juhq.jpeg";

export default styled.div`
  display: inline-block;
  width: 30vh;
  height: 30vh;
  background-image: url(${juhq});
  background-position: center;
  background-size: 100%;
  clip-path: polygon(
    50% 0%,
    83% 12%,
    100% 43%,
    94% 78%,
    68% 100%,
    32% 100%,
    6% 78%,
    0% 43%,
    17% 12%
  );
  transition: background-size 0.8s ease-in-out, transform 2s, filter 1.3s;

  @media screen and (prefers-reduced-motion) {
    transition: none;
  }

  &:focus,
  &:hover {
    background-size: 150vh;
    filter: hue-rotate(260deg);
    transform: rotate3d(3, 6, 3, 600deg);
  }
`;
