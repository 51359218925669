import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

export const useAnalytics = (): void => {
  const location = useLocation();
  ReactGA.initialize("G-FSDVLJYX96");

  useEffect(() => {
    ReactGA.send("pageview");
  }, [location]);
};

export default useAnalytics;
