import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { sendOpenModalEvent } from "../../utils/analytics";
import keyDownEvent from "../../utils/keyDownEvent";
import Datalist from "../Datalist/Datalist";
import Dummy from "../Dummy/Dummy";
import Modal from "../Modal/Modal";
import routes, { gameRoutes } from "../Router/routes";

const dummyElement = {
  path: "home",
  title: "Home",
  component: <Dummy />,
  numpadCode: "0000",
};

const listOfRoutes = routes().concat(
  gameRoutes.map((r) => ({ ...r, path: `games/${r.path}` }))
);
const values = listOfRoutes
  .filter(({ hideFromModal }) => !hideFromModal)
  .concat(dummyElement);

const NavigateModal = (): JSX.Element | null => {
  const [open, setOpen] = useState<boolean>(false);
  const [keysDown, setKeysDown] = useState<string>("");
  const [page, setPage] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(false);
  }, [location]);

  useEffect(() => {
    if (page === "home" || page === "/home") {
      navigate("/");
      setPage(null);
      setOpen(false);
    } else if (page) {
      navigate(page[0] === "/" ? page : `/${page}`);
      setOpen(false);
      setPage(null);
    }
  }, [navigate, page]);

  useEffect(() => {
    const keydown = ({ key, target }: KeyboardEvent) => {
      const shouldContinue =
        !open &&
        target === document.body &&
        location.pathname !== "/games/hangman";

      if (shouldContinue) {
        setKeysDown(keysDown + key);

        if (key === "n") {
          sendOpenModalEvent();
          setOpen(true);
          setPage(null);

          // to prevent letter "n" to be inputted
          setTimeout(() => {
            inputRef?.current?.focus();
          });
        }
      }
    };

    return keyDownEvent(keydown);
  }, [keysDown, open, location]);

  useEffect(() => {
    if (keysDown === "juhqbest") {
      // eslint-disable-next-line no-alert
      alert("You're damn right!");
    }
  }, [keysDown]);

  const handleSelect = useCallback((selection: string) => {
    setOpen(false);
    setPage(selection);
  }, []);

  return (
    <Modal open={open} onClose={handleClose}>
      <Datalist inputRef={inputRef} values={values} onSelect={handleSelect} />
    </Modal>
  );
};

export default NavigateModal;
