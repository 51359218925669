import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import useTimezone from "../../hooks/timezone";
import URL from "../../utils/juhq-domain-address";
import BuildInfo from "../BuildInfo/BuildInfo";
import ButtonNoBorder from "../Buttons/ButtonNoBorder/ButtonNoBorder";
import ComicSansifyButton from "../ComicSansifyButton/ComicSansifyButton";
import CorporateFooter from "../CorporateFooter/CorporateFooter";
import ExternalLink from "../ExternalLink/ExternalLink";
import FontColorPicker from "../FontColorPicker/FontColorPicker";
import Grid, { GridItem } from "../Grid/Grid";
import HairOnScreen from "../HairOnScreen/HairOnScreen";
import RSSIcon from "../Icons/RSSIcon";
import ShareURL from "../ShareURL/ShareURL";
import ShieldIo from "../ShieldIo/ShieldIo";
import FontFamilySlider from "../Sliders/FontFamilySlider/FontFamilySlider";
import FontSizeSlider from "../Sliders/FontSizeSlider/FontSizeSlider";
import ButtonRow from "./ButtonRow";

const CenterGrid = styled(Grid)`
  text-align: center;
`;

const Margin = styled.div`
  margin: 2rem 0;
`;

const GridOk = styled(Grid)`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: max-content;
`;

const GridItemRight = styled(GridItem)`
  justify-items: right;
`;

const Footer = (): JSX.Element => {
  const date = useTimezone();
  const [isBirthday, setIsBirthday] = useState(false);

  useEffect(() => {
    const isMyBirthday = date.getDate() === 21 && date.getMonth() === 2;
    setIsBirthday(isMyBirthday);
  }, [date]);

  return (
    <CorporateFooter>
      <CenterGrid>
        <ShieldIo
          link="https://validator.w3.org/nu/?doc=https://juhq.fi"
          service="w3c-validation/default?targetUrl=https%3A%2F%2Fjuhq.fi"
          alt="Valid HTML?"
        />

        <p>
          <small>💎 &copy; Kaikki oikeutetut pidätetään.</small>
        </p>

        {isBirthday && (
          <p>
            <Link to="/birthdayboy">Who is the birthday boy?</Link>
          </p>
        )}

        <iframe
          src="https://giphy.com/embed/anjRJ4nv9WJzO"
          width="48"
          height="48"
          frameBorder="0"
          title="netscape"
        />
      </CenterGrid>

      <Margin>
        <GridOk>
          <GridItem style={{ justifySelf: "start" }}>
            <ButtonNoBorder type="button" onClick={() => window.scrollTo(0, 0)}>
              ⬆ Back to top
            </ButtonNoBorder>
          </GridItem>

          <GridItem style={{ justifyItems: "center" }}>{URL}</GridItem>
          <GridItem style={{ justifyItems: "center" }}>
            <ExternalLink to="/rss.xml" favicon={false}>
              <RSSIcon />
            </ExternalLink>
          </GridItem>

          <GridItemRight>
            <ShareURL />
          </GridItemRight>
        </GridOk>
      </Margin>

      <Margin>
        <ButtonRow />
      </Margin>

      <HairOnScreen />
      <Margin>
        <GridOk>
          <GridItem>
            <video src="https://i.imgur.com/y3tPE.mp4" muted loop autoPlay />
          </GridItem>
          <GridItem>
            <BuildInfo />
            <ComicSansifyButton />
            <FontSizeSlider min={1} max={5} />
            <FontColorPicker />
            <FontFamilySlider />
          </GridItem>
          <GridItemRight>
            <Link to="/human-named-juhq">
              <img
                src="/images/human-named-juhq/bdd6ca14-dae7-4fae-9e0f-27125b45acef.jpeg"
                alt="Human named JuhQ"
                height={100}
              />
            </Link>
          </GridItemRight>
        </GridOk>
      </Margin>
    </CorporateFooter>
  );
};

export default memo(Footer);
