import { memo, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { sendOpenFullScreenEvent } from "../../utils/analytics";
import keyDownEvent from "../../utils/keyDownEvent";

const FullScreen = (): null => {
  const location = useLocation();

  useEffect(() => {
    const keydown = ({ key, target }: KeyboardEvent) => {
      const shouldContinue =
        target === document.body && location.pathname !== "/games/hangman";

      if (shouldContinue && key === "f") {
        if (!document.fullscreenElement) {
          sendOpenFullScreenEvent();
          document.documentElement.requestFullscreen?.();
        } else {
          document.exitFullscreen?.();
        }
      }
    };

    return keyDownEvent(keydown);
  }, [location]);

  return null;
};

export default memo(FullScreen);
