import get from "lodash/get";
import { ChangeEvent } from "react";

// eslint-disable-next-line
type PipeFns = Array<(arg: any) => any>;

function pickAndPipe<T = HTMLInputElement>(selector: string, ...fns: PipeFns) {
  return (arg: ChangeEvent<T>) =>
    fns.reduce((result, fn) => fn(result), get(arg, selector));
}

export default pickAndPipe;
