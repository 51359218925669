import { useEffect } from "react";

import useGiphy from "../../hooks/api/giphy";
import { sendGiphyAnalytics } from "../../utils/analytics";
import Webp from "../Webp/Webp";

interface Props {
  term: string;
  count?: number;
}

const Giphy = ({ term, count }: Props): JSX.Element | null => {
  const images = useGiphy({ term, count });

  useEffect(() => {
    if (images?.length) {
      images.map(({ gif }) => gif).forEach(sendGiphyAnalytics);
    }
  }, [images]);

  if (!images?.length) {
    return null;
  }

  return (
    <>
      {images.map(({ gif, webp }) => (
        <Webp gif={gif} webp={webp} key={gif} />
      ))}
    </>
  );
};

Giphy.defaultProps = { count: 1 };

export default Giphy;
