import { useEffect, useState } from "react";

import timezone from "../utils/timezone";

const useTimezone = (updateInterval = 60) => {
  const [date, setDate] = useState<Date>(timezone());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(timezone());
    }, updateInterval * 1000);

    return () => clearInterval(interval);
  }, [updateInterval]);

  return date;
};

export default useTimezone;
