import { memo } from "react";

import ExternalLink from "../ExternalLink/ExternalLink";

interface Props {
  url: string;
}

const ShareLinks = ({ url }: Props): JSX.Element => {
  const links = [
    {
      service: "Twitter",
      link: `http://twitter.com/share?text=juhqbest <3 ${document.title}&url=${url}&hashtags=juhq,best,love`,
    },
    { service: "Whatsapp", link: `https://wa.me/?text=${url}` },
    {
      service: "Telegram",
      link: `https://t.me/share/url?url=${url}&text=juhqbest <3 ${document.title}`,
    },
    {
      service: "Facebook",
      link: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    },
    {
      service: "Reddit",
      link: `https://reddit.com/submit?url=${url}&title=${document.title}`,
    },
    {
      service: "Pinterest",
      link: `http://pinterest.com/pin/create/link/?url=${url}&description=juhqbest <3`,
    },
  ];

  return (
    <>
      {links.map(({ service, link }) => (
        <ExternalLink key={service} to={link}>
          {service}
        </ExternalLink>
      ))}
    </>
  );
};

export default memo(ShareLinks);
