import styled from "styled-components";

interface Props {
  children: React.ReactNode[];
}

export default styled.div`
  display: grid;
  column-count: ${({ children }: Props) => children.length};
  column-gap: 2px;
  grid-auto-flow: column;
  grid-template-rows: max-content;
`;

export const GridItem = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
`;
