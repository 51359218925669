import memoize from "lodash/memoize";
import { useEffect, useState } from "react";

interface WikipediaOutput {
  extract: string;
  ns: number;
  pageid: number;
  title: string;
}

const queryParams =
  "origin=*&format=json&action=query&prop=extracts&exintro&explaintext&redirects=1";

const getWikipedia = memoize(
  (query: string, language): Promise<WikipediaOutput> =>
    fetch(
      `https://${language}.wikipedia.org/w/api.php?${queryParams}&titles=${query}&srprop=extracts,languagecode:${language}`
    )
      .then((data) => data.json())
      .then((data) => data.query.pages)
      .then((result) => Object.values(result))
      .then((result) => result[0] as WikipediaOutput)
);

const useWikipedia = (
  query: string,
  language: string
): WikipediaOutput | null => {
  const [data, setData] = useState<WikipediaOutput | null>(null);
  useEffect(() => {
    getWikipedia(query, language).then(setData);
  }, [query, language]);

  return data;
};

export default useWikipedia;
