/* eslint-disable styled-components-a11y/no-noninteractive-element-interactions */
import { useCallback, useEffect } from "react";
import styled from "styled-components";

import { sendCloseModalEvent } from "../../utils/analytics";
import keyDownEvent from "../../utils/keyDownEvent";
import ModalPortal from "./ModalPortal";

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(41 171 164 / 80%);

  @media (min-width: 800px) {
    backdrop-filter: blur(0.6rem);
  }
`;

const ModalContent = styled.div`
  position: absolute;
  top: calc(50% - 15rem);
  left: calc(50% - 15rem);
  min-width: 30rem;
  max-width: 100%;
  padding: 2rem;
  background: rgb(255, 255, 255);
  border-radius: 0.4rem;
  box-shadow: 0 0 17px -1px #2a7773;

  @media (max-width: 800px) {
    top: 20%;
    left: 0;
    min-width: 100%;
    margin: 0;
  }
`;

interface Props {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal = ({ open, onClose, children }: Props): JSX.Element | null => {
  const handleClose = useCallback(() => {
    sendCloseModalEvent();
    onClose();
  }, [onClose]);

  useEffect(() => {
    const keydown = ({ key }: KeyboardEvent) => {
      if (key === "Escape") {
        handleClose();
      }
    };

    return keyDownEvent(keydown);
  }, [handleClose]);

  if (!open) {
    return null;
  }

  return (
    <ModalPortal>
      <ModalWrapper onClick={handleClose} role="none">
        <ModalContent
          onKeyDown={(event) => event.stopPropagation()}
          onClick={(event) => event.stopPropagation()}
          role="dialog"
        >
          {children}
        </ModalContent>
      </ModalWrapper>
    </ModalPortal>
  );
};

export default Modal;
