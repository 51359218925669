import { GiphyFetch } from "@giphy/js-fetch-api";
import sampleSize from "lodash/sampleSize";
import { useEffect, useState } from "react";

interface Props {
  term: string;
  count?: number;
}

interface Image {
  gif: string;
  webp: string;
}

const useGiphy = ({ count, term }: Props): Image[] => {
  const [images, setImages] = useState<Image[]>([]);

  useEffect(() => {
    const controller = new AbortController();
    const gf = new GiphyFetch(process.env.REACT_APP_GIPHY_SDK_KEY as string);

    gf.search(term)
      .then(({ data }) => data)
      .then((data) => {
        const randomImages = sampleSize(data, count)?.map((imageData) => {
          const { url, webp } = imageData.images.fixed_height;
          return { gif: url, webp };
        });

        if (randomImages) {
          setImages(randomImages);
        }
      });

    return () => {
      controller.abort();
    };
  }, [count, term]);

  return images;
};

export default useGiphy;
