import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CursorTravel from "../CursorStats/CursorTravel/CursorTravel";
import CursorVelocity from "../CursorVelocity/CursorVelocity";
import Grid from "../Grid/Grid";
import ScrollTravel from "../Scroll/ScrollTravel/ScrollTravel";
import ScrollVelocity from "../Scroll/ScrollVelocity/ScrollVelocity";
import TimeOnSite from "../TimeOnSite/TimeOnSite";
import TimeToMoon from "../TimeToMoon/TimeToMoon";

const TravelStats = (): JSX.Element => {
  const [cursorPerSecond, setCursorPerSecond] = useState<number>(0);
  const [scrollPerSecond, setScrollPerSecond] = useState<number>(0);
  const [cursor, setCursor] = useState<number>(0);
  const [scroll, setScroll] = useState<number>(0);
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    setCursorPerSecond(cursor / time);
  }, [cursor, time]);

  useEffect(() => {
    setScrollPerSecond(scroll / time);
  }, [scroll, time]);

  return (
    <Grid>
      <p>
        <TimeOnSite onChange={setTime} />
        <br />
        <Link to="/daily-time-on-site">Daily time on site</Link>
      </p>
      <p>
        <CursorTravel onChange={setCursor} />, {cursorPerSecond.toFixed(2)} px/s
        <br />
        <TimeToMoon value={cursor} />
        <br />
        <CursorVelocity />
        <br />
        <Link to="/daily-cursor-travel">Daily cursor travel</Link>
      </p>
      <p>
        <ScrollTravel onChange={setScroll} />, {scrollPerSecond.toFixed(2)} px/s
        <br />
        <TimeToMoon value={scroll} />
        <br />
        <ScrollVelocity />
        <br />
        <Link to="/daily-scroll-travel">Daily scroll travel</Link>
      </p>
    </Grid>
  );
};

export default TravelStats;
