import { memo } from "react";

import ButtonNoBorder from "../Buttons/ButtonNoBorder/ButtonNoBorder";

const ComicSansifyButton = (): JSX.Element => (
  <ButtonNoBorder
    onClick={() => {
      document.querySelector("html")?.classList.add("comicsansify");
    }}
    type="button"
  >
    ComicSansify
  </ButtonNoBorder>
);

export default memo(ComicSansifyButton);
