import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import routes, { Route, gameRoutes } from "../components/Router/routes";

const listOfRoutes = routes().concat(
  gameRoutes.map((r) => ({ ...r, path: `games/${r.path}` }))
);

const useLocationRoute = (): Route | undefined => {
  const [route, setRoute] = useState<Route | undefined>(undefined);
  const location = useLocation();

  useEffect(() => {
    const [, ...pathList] = location.pathname.toLowerCase().split("");
    const locationPath = pathList.join("");
    const result = listOfRoutes.find(
      ({ path }) => path.toLowerCase() === locationPath
    );

    setRoute(result);
  }, [location]);

  return route;
};

export default useLocationRoute;
