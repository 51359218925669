interface Song {
  title: string;
  id: string;
}

const random: Song[] = [
  { id: "fJ9rUzIMcZQ", title: "Queen - Bohemian Rhapsody" },
  { id: "f4Mc-NYPHaQ", title: "Queen - I Want To Break Free" },
  { id: "uMK0prafzw0", title: "Die Antwoord - UGLY BOY" },
  { id: "cegdR0GiJl4", title: "Die Antwoord - Enter The Ninja" },
  { id: "fPO76Jlnz6c", title: "Coolio - Gangsta's Paradise (feat. L.V.)" },
  { id: "FQlAEiCb8m0", title: "Stardust - Music Sounds Better With You" },
  { id: "djV11Xbc914", title: "a-ha - Take On Me (Official 4K Music Video)" },
  { id: "ub747pprmJ8", title: "Fatboy Slim - Right Here, Right Now" },
  { id: "wCDIYvFmgW8", title: "Fatboy Slim - Weapon Of Choice" },
  {
    id: "KQ6zr6kCPj8",
    title: "LMFAO ft. Lauren Bennett, GoonRock - Party Rock Anthem",
  },
  { id: "mMfxI3r_LyA", title: "Modjo - Lady (Hear Me Tonight)" },
  {
    id: "8UVNT4wvIGY",
    title: "Gotye - Somebody That I Used To Know (feat. Kimbra)",
  },
  { id: "Hy8kmNEo1i8", title: "Scatman (ski-ba-bop-ba-dop-bop)" },
  {
    id: "MV_3Dpw-BRY",
    title: "Kavinsky - Nightcall (Drive Original Movie Soundtrack)",
  },
  {
    id: "VFZNvj-HfBU",
    title: "Daði Freyr (Daði & Gagnamagnið) – Think About Things",
  },
  { id: "4JkIs37a2JE", title: "Jamiroquai - Virtual Insanity" },
  { id: "EDwb9jOVRtU", title: "Madonna - Hung Up" },
  { id: "XgztfRBc2jM", title: "Eiffel 65 - Blue" },
  { id: "dQw4w9WgXcQ", title: "Rick Astley - Never Gonna Give You Up" },
  { id: "qmsbP13xu6k", title: "Mr Oizo - Flat beat" },
  {
    id: "h61QG4s0I3U",
    title: "The Supermen Lovers (feat. Mani Hoffman) · Starlight",
  },
  { id: "9Dz6hK585HM", title: "I Remember" },
  { id: "NI6aOFI7hms", title: "The Cardigans - Lovefool" },
  { id: "zK1mLIeXwsQ", title: "deadmau5 & Kaskade - I Remember" },
  { id: "CJinWua98NA", title: "Capital Cities - Kangaroo Court" },
  { id: "QtXby3twMmI", title: "Coldplay - Adventure Of A Lifetime" },
  { id: "v0NSeysrDYw", title: "Bob Sinclar - Love Generation" },
  { id: "y6120QOlsfU", title: "Darude - Sandstorm" },
  { id: "pT68FS3YbQ4", title: "The Strokes - You Only Live Once" },

  { id: "PWgvGjAhvIw", title: "OutKast - Hey Ya!" },
  { id: "6NXnxTNIWkc", title: "4 Non Blondes - What's Up" },
  { id: "h_D3VFfhvs4", title: "Michael Jackson - Smooth Criminal" },
  { id: "-e_3Cg9GZFU", title: "Skrillex - Summit (feat. Ellie Goulding)" },
  {
    id: "yURRmWtbTbo",
    title: "Michael Jackson - Don’t Stop 'Til You Get Enough",
  },
  { id: "iTFrCbQGyvM", title: "Underworld - Born Slippy" },
  { id: "iArXv64tCJA", title: "Peter Bjorn And John - Young Folks" },
  { id: "Gs069dndIYk", title: "Earth, Wind & Fire - September" },
  { id: "QSwilU0kJF0", title: "David Guetta - The World Is Mine" },
  { id: "MaO-PvokDMw", title: "Don't Stop the Music" },
  { id: "qL7zrWcv6XY", title: "Drake - One Dance" },
  { id: "fHI8X4OXluQ", title: "The Weeknd - Blinding Lights" },
  {
    id: "hAx6mYeC6pY",
    title: "Sophie Ellis-Bextor - Murder On The Dancefloor",
  },
  { id: "I7HahVwYpwo", title: "The Black Eyed Peas - Meet Me Halfway" },
  { id: "8AHCfZTRGiI", title: "Johnny Cash - Hurt" },
  { id: "oVWEb-At8yc", title: "SABATON - Bismarck" },
  { id: "kFr8SdOS5ic", title: "The Prodigy - The Day Is My Enemy" },
  { id: "v4xZUr0BEfE", title: "The HU - Yuve Yuve Yu" },
  { id: "V9AbeALNVkk", title: "Twisted Sister - We're Not Gonna Take it" },
  { id: "_lK4cX5xGiQ", title: "Tenacious D - Tribute" },
  { id: "CSvFpBOe8eY", title: "System Of A Down - Chop Suey!" },
  { id: "uT3SBzmDxGk", title: "2CELLOS - Thunderstruck" },
  {
    id: "B9FzVhw8_bY",
    title: "The Dead South - In Hell I'll Be In Good Company",
  },
  { id: "jM8dCGIm6yc", title: "The HU - Wolf Totem" },
  { id: "v2AC41dglnM", title: "AC/DC - Thunderstruck" },
  { id: "lzeNBRbWXpI", title: "SABATON - Uprising" },
  { id: "6fVE8kSM43I", title: "Slipknot - Duality" },
  { id: "iywaBOMvYLI", title: "System Of A Down - Toxicity" },
  { id: "i0VtTMs6Mz4", title: "Great Big White World - Marilyn Manson" },
  { id: "cTCXDdHUFE8", title: "The Passion for Music – Short" },
  { id: "8PqUqk4bBOk", title: "All Right" },
  { id: "pyfUekFpoxQ", title: "N-Files (outro)" },
  { id: "jgyShFzdB_Q", title: "Time" },
  { id: "tAVnGvRwXew", title: "Oogway Ascends" },
  { id: "Tjp8cj8Vzyo", title: "Davy Jones" },
  { id: "lj8Q7jZOBgg", title: "No Time for Caution" },
  { id: "KckEf9KhMHo", title: "S.T.A.Y." },
  { id: "pOxEn9bRFeA", title: "Like a Dog Chasing Cars" },
  { id: "ww4opaJ6umk", title: "Up Is Down" },
  { id: "CpHkMZxNLV0", title: "Chevaliers De Sangreal" },
  { id: "Vp6_Rjxs9U8", title: "A Way of Life" },
  { id: "Rbwxf69Laa8", title: "He's A Pirate" },
  { id: "B7QbEl9VFAY", title: "Mountains" },
  { id: "Eu9nPPJhcn4", title: "Tennessee" },
  { id: "BflqXfguhMU", title: "Flight" },
  { id: "8TmTSAf_H1A", title: "Is She With You?" },
  { id: "KaR5I_sT7kQ", title: "Drink Up Me Hearties Yo Ho" },
  {
    id: "HH1l5okI9mE",
    title: "What Are You Going to Do When You Are Not Saving the World?",
  },
  { id: "ypmrU7QfiDc", title: "A Dark Knight" },
  { id: "78u2ywj2uLk", title: "Day One" },
  { id: "Vd6MA5KGXIA", title: "Sacred Pool Of Tears" },
  { id: "GFY7i7pJAXg", title: "The Kraken" },
  {
    id: "ecRcT6TPuCs",
    title:
      'Hoist the Colours (From "Pirates of the Caribbean: At World\'s End"/Soundtrack Version)',
  },
  { id: "KAH3VisDQpo", title: "First Step" },
  { id: "KpcfHX0zAsc", title: "Remember" },
  { id: "nKSsLEYc1xs", title: '... To Die For (From "The Lion King"/Score)' },
  { id: "S238gmSaVM4", title: "Lost but Won" },
  { id: "s2Lo047gK0U", title: "Why so Serious?" },
  { id: "JZeNYaTmVP4", title: "Jack Sparrow" },
  { id: "dkWm59GfLz4", title: "Cornfield Chase" },
  { id: "slrB29PD7wk", title: "Why Do We Fall?" },
  { id: "X-k_Wmy4oZc", title: "Heart Of A Volunteer" },
  { id: "MV9H6dIWohk", title: "A Watchful Guardian" },
  { id: "AnNKsk2a0fI", title: "Finale (William Tell Overture)" },
  {
    id: "QUPtsJjfxm0",
    title: 'King of Pride Rock (From "The Lion King"/Score)',
  },
  { id: "lb_e3xpkRnw", title: "Discombobulate" },
  { id: "WAN6oZeCV1M", title: "The Electro Suite" },
  { id: "FNBAmsriyY8", title: "Remember Who You Are" },
  { id: "ixS1D0Lu8Ds", title: "I'm Not a Hero" },
  { id: "QtHO0P4bKrY", title: "Molossus" },
  { id: "CPgPxGX6nNo", title: "Mombasa" },
  { id: "KMrBG9AqnPU", title: "Alex On The Spot" },
  { id: "9OpVbFciTfI", title: "Stampede" },
  { id: "ipGNuFXubL8", title: "Sea Wall" },
  { id: "PA_cPIS-9D0", title: "A Way of Life (Tetraspace Remix)" },
  { id: "VJDXOWqs_HI", title: "Beautiful Lie" },
  { id: "5Hk_v9vfFaY", title: "Journey to the Line" },
  { id: "P93JhrkedV0", title: "A Small Measure of Peace" },
  { id: "nEsUzApGEGg", title: "Beautiful Lie" },
  { id: "K0_5Nup_uBw", title: 'Roll Tide (From "Crimson Tide" Soundtrack)' },
  { id: "F-3Wg5LppXk", title: "Leave No Man Behind" },
  { id: "fm-m9z4ruOk", title: "Zen Ball Master" },
  { id: "by2pM_0Sf8c", title: "Tears in the Rain" },
  { id: "U0HSGkCa_Fo", title: 'This Land (From "The Lion King"/Score)' },
  { id: "IJrtpzck67g", title: "Gap" },
  { id: "9MzHTDQSS24", title: "The Arrival of Kai" },
  { id: "86EPZphKBhg", title: "Hero" },
  { id: "wwvjMuWOelM", title: "... And Then I Kissed Him" },
  { id: "Ygdi4g6ZkSU", title: "My Enemy" },
  { id: "B7WvGKUQfAI", title: "To Know My Enemy" },
  { id: "SkCCEIDdXzo", title: "Gotham's Reckoning" },
  { id: "j_-8QPxvF-A", title: "Po Finds The Truth" },
  { id: "LY3Ye4Pc4yQ", title: "Shifu Faces Tai Lung" },
  { id: "qvxsmmvxxNc", title: "Imagine The Fire" },
  { id: "4FgHkfi-9Cs", title: "Supermarine" },
  { id: "lMWJJShJdnA", title: "Reflections of Mufasa" },
  { id: "MvEfOVlyijk", title: "I Will Find Him" },
  { id: "j3-xuIEs2uU", title: "All Of Them!" },
  { id: "yY4Ck7-tvXA", title: "Rise" },
  { id: "BWrHOcMH_UU", title: "Red Warrior" },
  { id: "bJFMnN_4mPM", title: "Coward" },
  { id: "GLdjURq4cFI", title: "This Is My World" },
  { id: "6ryCfH8C1ME", title: "The Dragon Warrior" },
  { id: "JEc1uhdKRko", title: "The Mole" },
  { id: "_-_4V0lrXag", title: "Dust" },
  { id: "JvGeEz5nWA0", title: "Maestro" },
  { id: "HLWtpZwfUfc", title: "Spectres in the Fog" },
  {
    id: "BbSLZeCdvK8",
    title:
      "At Wit's End (From \"Pirates of the Caribbean: At World's End\"/Score)",
  },
  {
    id: "-3Gd6yMZMDA",
    title:
      'What Shall We Die For (From "Pirates of the Caribbean: At World\'s End"/Score)',
  },
  { id: "8h3SusINdAI", title: "Mesa" },
  { id: "E3uWHtUkUoI", title: "Wallace" },
  {
    id: "4FOKNY0gM6M",
    title:
      'Mermaids (From "Pirates of the Caribbean: On Stranger Tides"/Score)',
  },
  { id: "pbJDahj7Z5A", title: "Life's Not Fair" },
  { id: "L831_m-MeBs", title: "Equation" },
  { id: "udBLIulzITo", title: "Injection" },
  { id: "-Le4OUSse4g", title: "Rafiki's Fireflies" },
  { id: "PVuLDXxI0Mw", title: "Hunger" },
  { id: "XMgMOYKZ96g", title: "Wheel of Fortune" },
  { id: "oCcKN8dKOcw", title: "Their War Here" },
  { id: "fX9cwsifaj4", title: "Elephant Graveyard" },
  { id: "4t3cVPVGemY", title: "Battle for Pride Rock" },
  { id: "YuvwTnkGVK0", title: "Homeland (Main Title)" },
  { id: "CAt0SeaDWiQ", title: "Solomon" },
  { id: "NX7yOVVr_d0", title: "528491" },
  { id: "M2oVRx0ymEI", title: "Dreaming of the Crash" },
  { id: "HtyB5Wiq_Mw", title: "Joi" },
  { id: "ZRFQALuS8so", title: "Planet Earth II Suite" },
  { id: "iYYRH4apXDo", title: "David Bowie – Space Oddity" },
  { id: "GgnClrx8N2k", title: "The Rolling Stones - Sympathy For The Devil" },
  { id: "1vrEljMfXYo", title: "John Denver - Take Me Home, Country Roads" },
  { id: "UipTt-qqZOE", title: "David Bowie - Life On Mars (2016 Mix)" },
  { id: "RbmS3tQJ7Os", title: "The Rolling Stones - Gimme Shelter" },
  { id: "Dy4HA3vUv2c", title: "Blue Oyster Cult - (Don't Fear) The Reaper" },
  {
    id: "nrIPxlFzDi0",
    title: "The Rolling Stones - (I Can't Get No) Satisfaction",
  },
  { id: "4-43lLKaqBQ", title: "The Animals - House of the Rising Sun" },

  { id: "I_2D8Eo15wE", title: "Ram Jam - Black Betty" },
  { id: "0J2QdDbelmY", title: "The White Stripes - 'Seven Nation Army'" },
  {
    id: "Kt-tLuszKBA",
    title: "Guardians of the Galaxy Awesome Mix Vol 1 Vol 2 Full Soundtrack",
  },
  {
    id: "TLV4_xaYynY",
    title: "The Jimi Hendrix Experience - All Along The Watchtower",
  },
  { id: "N-aK6JnyFmk", title: "The Mamas & The Papas - California Dreamin'" },
  { id: "VixdIglCZXk", title: "The House of the Rising Sun" },
  { id: "NUTGr5t3MoY", title: "Green Day - Basket Case" },
  { id: "ZWijx_AgPiA", title: "Creedence Clearwater Revival - Fortunate Son" },
  { id: "FTQbiNvZqaY", title: "Toto - Africa" },
  { id: "ZhIsAZO5gl0", title: "Kiss - I Was Made For Lovin' You" },
  { id: "Soa3gO7tL-c", title: "Green Day: Boulevard Of Broken Dreams" },
  { id: "DtVBCG6ThDk", title: "Elton John - Rocket Man" },
  { id: "y9ANOzmSKQg", title: "The White Stripes-Seven Nation Army" },
  { id: "6c1BThu95d8", title: "The Rolling Stones - She's A Rainbow" },
  { id: "6Ejga4kJUts", title: "The Cranberries - Zombie" },
  {
    id: "qeMFqkcPYcg",
    title:
      "Eurythmics, Annie Lennox, Dave Stewart - Sweet Dreams (Are Made Of This)",
  },
  { id: "bpOSxM0rNPM", title: "Arctic Monkeys - Do I Wanna Know?" },
  { id: "DnGdoEa1tPg", title: "System Of A Down - Lonely Day" },
  { id: "bx1Bh8ZvH84", title: "Oasis - Wonderwall" },
  {
    id: "krxU5Y9lCS8",
    title: "The Rolling Stones - You Can’t Always Get What You Want",
  },
  { id: "gxEPV4kolz0", title: "Billy Joel - Piano Man" },
  { id: "XfR9iY5y94s", title: "Men At Work - Down Under" },
  { id: "tAGnKpE4NCI", title: "Metallica - Nothing Else Matters" },
  { id: "8SbUC-UaAxE", title: "Guns N' Roses - November Rain" },

  { id: "CKti7QixnJI", title: "Talking Heads - Psycho Killer" },
  { id: "sHbKdI26RkE", title: "House Of The Rising Sun" },
  { id: "r5NzAksjfDI", title: "Fortunate Son" },
  { id: "x2KRpRMSu4g", title: "The Who - Baba O'riley" },
];

export default random;
