import { memo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import CorporateLogo from "./CorporateLogo";

const Container = styled.footer`
  padding: 2rem;
  backdrop-filter: blur(0.6rem);
  background-color: rgb(31 38 122 / 40%);
  color: #fff;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const Links = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const LinkM = styled(Link)`
  color: #fff;
  font-size: 1.2rem;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const Copyright = styled.p`
  font-size: 1.2rem;
`;

const links = [
  { label: "Home", url: "/" },
  { label: "About Us", url: "/about-me" },
  { label: "Contact Us", url: "/banneri-vaihto" },
  { label: "Privacy Policy", url: "/excuse-generator" },
];

interface Props {
  children?: React.ReactChild[];
}

const CorporateFooter = ({ children }: Props): JSX.Element => (
  <Container>
    {children}

    <Wrapper>
      <Link to="/">
        <CorporateLogo />
      </Link>
      <Links>
        {links.map(({ url, label }) => (
          <LinkM key={label} to={url}>
            {label}
          </LinkM>
        ))}
      </Links>
      <Copyright>&copy; 2021 - this year</Copyright>
    </Wrapper>
  </Container>
);

CorporateFooter.defaultProps = { children: undefined };

export default memo(CorporateFooter);
