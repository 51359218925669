const H = "h";
const T = "t";
const P = "p";
const S = "s";

const W = "w";

const J = "j";
const U = "u";
const Q = "q";

const F = "f";
const I = "i";

const COLON = ":";
const FORWARD_SLASH = "/";
const DOT = ".";

const HTTPS = H + T + T + P + S;
const WWW = W + W + W;
const DOMAIN = J + U + H + Q;
const TLD = F + I;

const PROTOCOL = HTTPS + COLON + FORWARD_SLASH + FORWARD_SLASH;

const URL = PROTOCOL + WWW + DOT + DOMAIN + DOT + TLD;

export default URL;
