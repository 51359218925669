import { useCallback, useEffect, useState } from "react";

const useCursorVelocity = (): number => {
  const [velocity, setVelocity] = useState(0);
  const [lastMouseY, setLastMouseY] = useState(0);
  const [lastTimestamp, setLastTimestamp] = useState(0);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      const mouseY = event.clientY;
      const deltaY = mouseY - lastMouseY;
      const timeDelta = Date.now() - lastTimestamp;
      const newVelocity = deltaY / timeDelta;

      setVelocity(newVelocity);
      setLastMouseY(mouseY);
      setLastTimestamp(Date.now());
    },
    [lastMouseY, lastTimestamp]
  );

  useEffect(() => {
    setLastTimestamp(Date.now());
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove]);

  return Number(velocity.toFixed(4));
};

export default useCursorVelocity;
