import { useEffect } from "react";

import useTimePassed from "../../hooks/timepassed";

interface Props {
  onChange: (val: number) => void;
}

const TimeOnSite = ({ onChange }: Props): JSX.Element => {
  const time = useTimePassed();

  useEffect(() => {
    onChange(time);
  }, [onChange, time]);

  return <>{time.toFixed(2)} seconds</>;
};

export default TimeOnSite;
