/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";

import useCursorTravel from "../../../hooks/cursortravel";
import useLocalStorage from "../../../hooks/storage/localstorage";
import compareDates from "./utils";

interface Data {
  travel: number;
  year: number;
  month: number;
  day: number;
}

const StoreCursorTravel = (): null => {
  const [localValue, setLocalValue] = useLocalStorage<Data[]>(
    "cursor-daily-travel"
  );
  const { distance } = useCursorTravel();

  useEffect(() => {
    const dateObj = new Date();
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const obj = { travel: distance, year, month, day };

    if (!localValue) {
      setLocalValue([obj]);
    } else {
      const value = localValue.find((v) =>
        compareDates(v, { year, month, day })
      );

      if (!value) {
        setLocalValue([...localValue, obj]);
      } else {
        setLocalValue(
          localValue.map((v) =>
            compareDates(v, { year, month, day })
              ? { ...v, travel: distance + v.travel }
              : v
          )
        );
      }
    }
  }, [distance]);

  return null;
};

export default StoreCursorTravel;
