import { memo, useEffect, useState } from "react";

import WordSlider from "../WordSlider/WordSlider";

const commonFontFamilies = [
  "Arial",
  "Arial Black",
  "Helvetica",
  "Verdana",
  "Tahoma",
  "Trebuchet MS",
  "Times New Roman",
  "Georgia",
  "Palatino Linotype",
  "Courier New",
  "Lucida Sans",
  "Impact",
  "Geneva",
  "Comic Sans MS",
  "cursive",
  "Gadget",
  "Futura",
  "Gill Sans",
  "Menlo",
  "Monaco",
  "Fantasy",
  "system-ui",
  "monospace",
];

const FontFamilySlider = (): JSX.Element => {
  const [font, setFont] = useState<string>(commonFontFamilies[0]);

  useEffect(() => {
    document.body.style.fontFamily = font;
  }, [font]);

  return (
    <WordSlider
      words={commonFontFamilies}
      setWord={setFont as React.Dispatch<React.SetStateAction<string | null>>}
      fontStyling
    />
  );
};

export default memo(FontFamilySlider);
