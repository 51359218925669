/* stylelint-disable a11y/media-prefers-reduced-motion */
/* stylelint-disable declaration-colon-newline-after */
/* stylelint-disable plugin/no-low-performance-animation-properties */

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import keyDownEvent from "../../utils/keyDownEvent";
import Portal from "../Portal/Portal";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const HamburgerButton = styled.button`
  display: flex;
  width: 2.3rem;
  height: 1rem;
  align-items: flex-end;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;

const HamburgerIcon = styled.div<{ isOpen: boolean }>`
  width: 24px;
  height: 2px;
  background-color: #000;
  transform: ${({ isOpen }) =>
    isOpen ? "rotate(45deg) translate(0, -5px)" : "translate(6px, -5px)"};

  &::before,
  &::after {
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: #000;
    content: "";
    transition: transform 0.2s ease-in-out;
  }

  &::before {
    transform: ${({ isOpen }) =>
      isOpen ? "rotate(-180deg) translate(12px, 0)" : "translate(-12px, -5px)"};
  }

  &::after {
    transform: ${({ isOpen }) =>
      isOpen ? "rotate(90deg) translate(0, 12px)" : "translate(-12px, 5px)"};
  }
`;

const MenuContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  background-color: #fff;
  cursor: default;
`;

const Menu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: ${({ isOpen }) => (isOpen ? "100vh" : "0")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  transition: height 0.2s ease-in-out;
`;

const MenuItem = styled(Link)`
  margin-bottom: 16px;
  color: #000;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
`;

const links = [
  { to: "/", name: "Home" },
  { to: "/vito", name: "Vito" },
  { to: "/veikko", name: "Veikko" },
  { to: "/about-me", name: "About" },
  { to: "/excuse-generator", name: "Logout" },
];

const HamburgerMenu = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const keydown = ({ key }: KeyboardEvent) => {
      if (key === "Escape") {
        setIsOpen(false);
      }
    };

    return keyDownEvent(keydown);
  }, [setIsOpen]);

  return (
    <Container role="navigation" className={isOpen ? "open" : "closed"}>
      <HamburgerButton onClick={toggleMenu} aria-label="Open menu">
        <HamburgerIcon isOpen={isOpen} />
      </HamburgerButton>
      <Portal>
        <MenuContainer isOpen={isOpen}>
          <Menu isOpen={isOpen}>
            {links.map((link) => (
              <MenuItem
                key={link.name}
                to={link.to}
                onClick={() => setIsOpen(false)}
              >
                {link.name}
              </MenuItem>
            ))}
          </Menu>
        </MenuContainer>
      </Portal>
    </Container>
  );
};

export default HamburgerMenu;
