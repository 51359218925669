import random from "lodash/random";
import { memo, useEffect, useState } from "react";
import styled from "styled-components";

// images from https://github.com/0x00019913/HairOnScreen

const Img = styled.img`
  position: fixed;
  top: ${() => random(1, 28)}vh;
  left: ${() => random(1, 70)}vw;
  opacity: 0.5;
  pointer-events: none;
`;

const HairOnScreen = (): JSX.Element => {
  const [hair, setHair] = useState<number>(1);
  useEffect(() => {
    setHair(random(1, 15));
  }, []);

  return <Img src={`/images/hair/assets/h${hair}.png`} alt="kjeh" />;
};

export default memo(HairOnScreen);
