import { ReactNode, memo } from "react";

import ExternalLink from "../ExternalLink/ExternalLink";
import TooltipContainer from "../TooltipContainer/TooltipContainer";
import Wikipedia from "../Wikipedia/Wikipedia";
import { removeWikiUrl } from "../Wikipedia/utils";

interface Props {
  to: string;
  query?: string;
  favicon?: boolean;
  children?: ReactNode;
}

const Wikilink = ({ to, query, favicon, children }: Props): JSX.Element => (
  <TooltipContainer>
    <span>
      <Wikipedia query={query || removeWikiUrl(to)} url={to} />
    </span>
    <ExternalLink to={to} favicon={favicon}>
      {children}
    </ExternalLink>
  </TooltipContainer>
);

Wikilink.defaultProps = {
  query: undefined,
  favicon: undefined,
  children: undefined,
};

export default memo(Wikilink);
