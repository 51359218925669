import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactGA from "react-ga4";
import { Metric } from "web-vitals";

export const initializeSentry = (): void => {
  Sentry.init({
    dsn: "https://a9393169d73042b1b0eedff6bdbf2753@o903477.ingest.sentry.io/5843072",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
};

interface EventProps {
  category: string;
  action: string;
  label?: string;
  value?: number;
}

const sendGoogleEvent = ({
  category,
  action,
  label,
  value,
}: EventProps): void => {
  ReactGA.initialize("G-FSDVLJYX96");
  ReactGA.event({
    category,
    action,
    label,
    value,
    nonInteraction: true,
  });
};

type Vitals = Pick<Metric, "id" | "name" | "value">;
export const sendWebVitalsToAnalytics = ({ id, name, value }: Vitals): void => {
  sendGoogleEvent({
    category: "Web Vitals",
    action: name,
    value: Math.round(name === "CLS" ? value * 1000 : value),
    label: id,
  });
};

const sendModalEvent = (action: string): void => {
  sendGoogleEvent({
    category: "Modal",
    action,
  });
};

export const sendOpenModalEvent = (): void => {
  sendModalEvent("Open");
};

export const sendCloseModalEvent = (): void => {
  sendModalEvent("Close");
};

const sendFullScreenEvent = (action: string): void => {
  sendGoogleEvent({
    category: "FullScreen",
    action,
  });
};

export const sendOpenFullScreenEvent = (): void => {
  sendFullScreenEvent("Open");
};

export const sendCloseFullScreenEvent = (): void => {
  sendFullScreenEvent("Close");
};

export const sendYoutubeAnalytics = ({
  action,
  label,
}: Pick<EventProps, "action" | "label">): void => {
  sendGoogleEvent({
    category: "Youtube",
    action,
    label,
  });
};

export const sendGiphyAnalytics = (url: string): void => {
  sendGoogleEvent({
    category: "Giphy",
    action: "Render giphy",
    label: url,
  });
};

export const sendExcuseAnalytics = (excuse: string): void => {
  sendGoogleEvent({
    category: "Excuse",
    action: "Render excuse",
    label: excuse,
  });
};

export const sendExternalClickAnalytics = (to: string): void => {
  sendGoogleEvent({
    category: "Click",
    action: "Click on external link",
    label: to,
  });
};

export const sendTenxSelectionAnalytics = (
  i: number,
  j: number,
  color: string
): void => {
  sendGoogleEvent({
    category: "Tenx",
    action: `Selection`,
    label: `Color: ${color}, i: ${i}, j: ${j}`,
  });
};

export const sendTenxLevelAnalytics = (level: number): void => {
  sendGoogleEvent({
    category: "Tenx",
    action: `Level`,
    label: `${level}`,
  });
};

export const sendTenxResetAnalytics = (level: number): void => {
  sendGoogleEvent({
    category: "Tenx",
    action: `Reset`,
    label: `${level}`,
  });
};

export const sendTenxWinAnalytics = (level: number): void => {
  sendGoogleEvent({
    category: "Tenx",
    action: `Win`,
    label: `${level}`,
  });
};

const memoryCategory = "Memory game";

export const sendMemoryGameWinAnalytics = (level: number): void => {
  sendGoogleEvent({
    category: memoryCategory,
    action: `Win`,
    label: `${level}`,
  });
};

export const sendMemoryGameLevelAnalytics = (level: number): void => {
  sendGoogleEvent({
    category: memoryCategory,
    action: `Level`,
    label: `${level}`,
  });
};

export const sendMemoryGameSelectColorAnalytics = (color: string): void => {
  sendGoogleEvent({
    category: memoryCategory,
    action: `Select`,
    label: `${color}`,
  });
};

const unnamedGameCategory = "Unnamed game";

export const sendUnnamedGameWinAnalytics = (level: number): void => {
  sendGoogleEvent({
    category: unnamedGameCategory,
    action: `Win`,
    label: `${level}`,
  });
};

export const sendUnnamedGameLevelAnalytics = (level: number): void => {
  sendGoogleEvent({
    category: unnamedGameCategory,
    action: `Level`,
    label: `${level}`,
  });
};

export const sendUnnamedGameResetAnalytics = (level: number): void => {
  sendGoogleEvent({
    category: unnamedGameCategory,
    action: `Reset`,
    label: `${level}`,
  });
};

export const sendUnnamedGameSelectAnalytics = (color: string): void => {
  sendGoogleEvent({
    category: unnamedGameCategory,
    action: `Select`,
    label: `${color}`,
  });
};

const tictactoeCategory = "Tic-tac-toe game";

export const sendTicTacToeGameWinAnalytics = (winner: number): void => {
  sendGoogleEvent({
    category: tictactoeCategory,
    action: `Win`,
    label: `${winner}`,
  });
};

export const sendTicTacToeGameResetAnalytics = (): void => {
  sendGoogleEvent({
    category: tictactoeCategory,
    action: `Reset`,
  });
};

export const sendTicTacToeGameSelectAnalytics = (
  i: number,
  turn: number
): void => {
  sendGoogleEvent({
    category: tictactoeCategory,
    action: `Select`,
    label: `${i}`,
    value: turn,
  });
};

export const sendTicTacToeGameStarterAnalytics = (starter: number): void => {
  sendGoogleEvent({
    category: tictactoeCategory,
    action: `Starter`,
    label: `${starter}`,
  });
};

const hangmanCategory = "Hangman game";

export const sendHangmanGameWordAnalytics = (label: string): void => {
  sendGoogleEvent({
    category: hangmanCategory,
    action: `Word`,
    label,
  });
};

export const sendHangmanGameWinAnalytics = (label: string): void => {
  sendGoogleEvent({
    category: hangmanCategory,
    action: `Win`,
    label,
  });
};

export const sendHangmanGameResetAnalytics = (): void => {
  sendGoogleEvent({
    category: hangmanCategory,
    action: `Reset`,
  });
};

export const sendHangmanGameLetterAnalytics = (label: string): void => {
  sendGoogleEvent({
    category: hangmanCategory,
    action: `Letter`,
    label,
  });
};

export const sendHangmanGameCategoryAnalytics = (label: string): void => {
  sendGoogleEvent({
    category: hangmanCategory,
    action: `Category`,
    label,
  });
};
