import { memo } from "react";
import styled from "styled-components";

import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import Kbd from "../Kbd/Kbd";
import VisibleInDesktop from "../VisibleInDesktop/VisibleInDesktop";
import HeaderMenuLink from "./HeaderMenuLink";
import HeaderMenuNumPad from "./HeaderMenuNumPad";

const Head = styled.header`
  position: sticky;
  z-index: 1;
  top: 0;
  display: block;
  overflow: hidden;
  max-height: 3.5rem;
  background: rgb(63 78 108 / 80%);
  color: white;

  @media print {
    position: inherit;
  }

  @media (min-width: 800px) {
    backdrop-filter: blur(0.6rem);
  }
`;

const Ul = styled.ul`
  padding: 0.25rem;
  margin: 0;
  color: white;

  li {
    display: inline-block;
    margin: 0 0.1rem;

    &:last-of-type {
      padding: 1rem 0.5rem;
    }
  }
`;

const Header = (): JSX.Element => {
  const links = [
    { to: "/", name: "Home" },
    { to: "/vito", name: "Vito" },
    { to: "/veikko", name: "Veikko" },
    { to: "/timeline", name: "Resume" },
    { to: "/virtual-horses", name: "Horses" },
    { to: "/backlog", name: "Backlog" },
    { to: "/radio", name: "Music" },
    { to: "/excuse-generator", name: "Excuse me" },
    { to: "/games/10x", name: "10x" },
    { to: "/download-virus", name: "Click here to download a virus" },
    {
      to: "/download-android-virus",
      name: "Android virus",
    },
  ];

  const openPopup = () =>
    window.open(
      "/popup.html",
      "_blank",
      "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=300,height=150,left=300,top=200"
    );

  return (
    <Head>
      <Ul className="menu">
        <li>
          <HeaderMenuNumPad />
        </li>
        <li>
          <HamburgerMenu />
        </li>
        {links.map(({ to, name }) => (
          <li key={name}>
            <HeaderMenuLink to={to} name={name} />
          </li>
        ))}
        <li>
          <button onClick={openPopup} type="button">
            Popup
          </button>
        </li>
        <li>
          <a href="/frameset.html" target="_blank" rel="noopener noreferrer">
            Frames! Best thing from the 90s!
          </a>
        </li>
        <VisibleInDesktop as="li">
          Hint: <Kbd>n</Kbd> for navigation.
        </VisibleInDesktop>
      </Ul>
    </Head>
  );
};

export default memo(Header);
