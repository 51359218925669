/* eslint-disable no-plusplus */

import { memo, useEffect } from "react";

// _r_ in name stands for rotate..

const RotateFavicon = (): null => {
  useEffect(() => {
    const element = document.querySelector(
      'head > link[rel="icon"]'
    ) as HTMLLinkElement;

    let jep = 1;
    const interval = setInterval(() => {
      element.href = `/favicon_r_${jep}.ico`;
      jep++;
      if (jep >= 5) {
        jep = 1;
      }
    }, 400);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return null;
};

export default memo(RotateFavicon);
