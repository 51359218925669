const distanceToMoonKM = 384400;
const kilometerToMillimeter = 1000000;
const secondsInYear = 31556926;
const distanceToMoonMM = distanceToMoonKM * kilometerToMillimeter;

interface Props {
  value: number;
}

const TimeToMoon = ({ value }: Props): JSX.Element => {
  const kmToToMoon = (distanceToMoonMM - value) / kilometerToMillimeter;
  const etaYearsToMoon = (distanceToMoonMM - value) / secondsInYear;

  return (
    <>
      {Math.max(0, kmToToMoon).toFixed(2)}km left to the Moon, ETA{" "}
      {Math.max(0, etaYearsToMoon).toFixed(2)} years
    </>
  );
};

export default TimeToMoon;
