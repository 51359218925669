import { memo } from "react";
import styled from "styled-components";

import Emoji from "../Emoji/Emoji";

const Div = styled.div`
  color: #000;
  text-align: center;
`;

const MillionthVisitor = (): JSX.Element => (
  <Div>
    <h1>🎉 WINNER WINNER 🎉</h1>
    <Emoji>🐗</Emoji>
    <p>Congratulations!</p>
    <p>You are the {(1e6).toLocaleString("en")}th visitor!</p>
    <p>
      You have won a walking tour of your own home or a local park. Have fun.
    </p>
  </Div>
);

export default memo(MillionthVisitor);
