/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";

import useLocalStorage from "../../../hooks/storage/localstorage";
import useTimePassed from "../../../hooks/timepassed";
import compareDates from "../../CursorStats/StoreCursorTravel/utils";

interface Data {
  time: number;
  year: number;
  month: number;
  day: number;
}

const StoreDailyTimeOnSite = (): null => {
  const [localValue, setLocalValue] =
    useLocalStorage<Data[]>("daily-time-on-site");
  const time = useTimePassed(1000);

  useEffect(() => {
    const dateObj = new Date();
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();

    if (!localValue) {
      setLocalValue([{ time: 1, year, month, day }]);
    } else {
      const value = localValue.find((v) =>
        compareDates(v, { year, month, day })
      );

      if (!value) {
        setLocalValue([...localValue, { time: 1, year, month, day }]);
      } else {
        setLocalValue(
          localValue.map((v) =>
            compareDates(v, { year, month, day })
              ? { ...v, time: v.time + 1 }
              : v
          )
        );
      }
    }
  }, [time]);

  return null;
};

export default StoreDailyTimeOnSite;
