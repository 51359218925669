import { Fragment, memo } from "react";

import useWikipedia from "../../hooks/api/wikipedia";
import ExternalLink from "../ExternalLink/ExternalLink";
import { getWikipediaLanguage } from "./utils";

interface Props {
  url: string;
  query: string;
}

const Wikipedia = ({ url, query }: Props): JSX.Element | null => {
  const data = useWikipedia(query, getWikipediaLanguage(url));

  if (!data) {
    return null;
  }

  return (
    <>
      {(data.extract || "")
        .split("\n")
        .filter((part) => part.trim() !== "")
        .map((part) => (
          <Fragment key={part}>
            {part}
            <br />
          </Fragment>
        ))}
      <br />
      <ExternalLink to={url}>{url}</ExternalLink>
    </>
  );
};

export default memo(Wikipedia);
