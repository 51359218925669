import { useEffect, useState } from "react";

import useCursorCoordinates from "./cursorcoordinates";

const useCursorTravel = (): { travel: number; distance: number } => {
  const coordinates = useCursorCoordinates();
  const [distance, setDistance] = useState<number>(0);
  const [travel, setTravel] = useState<number>(0);

  useEffect(() => {
    const { prev, current } = coordinates;
    const x = (prev.x - current.x) ** 2;
    const y = (prev.y - current.y) ** 2;

    setDistance(Math.sqrt(x + y));
  }, [coordinates]);

  useEffect(() => {
    setTravel((prev) => prev + distance);
  }, [distance]);

  return { travel, distance };
};

export default useCursorTravel;
