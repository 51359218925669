import { getDayOfYear } from "date-fns";

import timezone from "./timezone";

export const isWinter = (): boolean => {
  const date = timezone();

  const start = getDayOfYear(date) >= 335; // 1.12.
  const end = getDayOfYear(date) <= 79; // 20.3.

  return start || end;
};

export const isSpring = (): boolean => {
  const date = timezone();

  const start = getDayOfYear(date) >= 80; // 21.3.
  const end = getDayOfYear(date) <= 173; // 22.6.

  return start && end;
};

export const isSummer = (): boolean => {
  const date = timezone();

  const start = getDayOfYear(date) >= 174; // 23.6.
  const end = getDayOfYear(date) <= 266; // 23.9.

  return start && end;
};

export const isFall = (): boolean => {
  const date = timezone();

  const start = getDayOfYear(date) >= 267; // 24.9.
  const end = getDayOfYear(date) <= 334; // 31.11.

  return start && end;
};
