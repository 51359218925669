/* stylelint-disable plugin/no-low-performance-animation-properties */
/* stylelint-disable value-list-comma-newline-after, declaration-colon-newline-after */

// https://codepen.io/hmaw/pen/qBEMLxV

import styled from "styled-components";

const Pyro = styled.div`
  overflow: hidden;
  width: 5rem;
  height: 5rem;
  padding: 0;
  margin: 0;

  > .before,
  > .after {
    position: absolute;
    overflow: hidden;
    width: 5px;
    height: 5px;
    animation: 1s bang ease-out infinite backwards,
      1s gravity ease-in infinite backwards,
      5s position linear infinite backwards;
    border-radius: 50%;
    box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff;
    transform: translate3d(0, 0, 0);
    will-change: transform;

    @media screen and (prefers-reduced-motion) {
      animation: none;
    }
  }

  > .after {
    animation-delay: 1.25s, 1.25s, 1.25s;
    animation-duration: 1.25s, 1.25s, 6.25s;

    @media screen and (prefers-reduced-motion) {
      animation: none;
    }
  }

  @keyframes bang {
    to {
      box-shadow: 5px -229.6667px #ff00b7, 123px -104.6667px #ea00ff,
        38px 2.333px #ae00ff, -159px -128.6667px #80ff00,
        -40px -113.6667px #00ffd0, 79px -154.6667px #ff0051,
        236px 56.333px #009dff, 196px -7.6667px #007bff,
        120px -403.6667px #04ff00, 127px -215.6667px #00ffa6,
        123px -22.6667px #0fe, -218px -330.6667px #ffd900,
        89px -38.6667px #00ffd9, -74px -156.6667px #ff00f7,
        -48px 50.333px #aeff00, -234px -335.6667px #ff006f,
        -188px -318.6667px #ef0, -4px -97.6667px fuchsia,
        -107px -227.6667px #f60, 116px -415.6667px #ff2b00,
        207px -314.6667px #ff0004, -241px -117.6667px #ae00ff,
        -193px 59.333px #6f0;
    }
  }

  @keyframes gravity {
    to {
      opacity: 0;
      transform: translateY(100px);
    }
  }

  @keyframes position {
    0%,
    19.9% {
      margin-top: 10px;
      margin-left: 40px;
      opacity: 1;
    }

    20%,
    39.9% {
      margin-top: 40px;
      margin-left: 30px;
    }

    40%,
    59.9% {
      margin-top: 20px;
      margin-left: 70px;
    }

    60%,
    79.9% {
      margin-top: 30px;
      margin-left: 20px;
      opacity: 0;
    }

    80%,
    99.9% {
      margin-top: 30px;
      margin-left: 80px;
    }
  }
`;

const Fireworks = (): JSX.Element => (
  <Pyro>
    <div className="before" />
    <div className="after" />
  </Pyro>
);

export default Fireworks;
