const getTime = (date: Date): string => {
  const originalMinutes = date.getMinutes();
  const originalHours = date.getHours();

  const hours = originalHours || "";

  const minutes =
    originalHours && originalMinutes < 10
      ? `0${originalMinutes}`
      : originalMinutes;

  return `${hours}${minutes}`;
};

export default getTime;
