import styled from "styled-components";

const getDomain = (url: string) => {
  const parser = document.createElement("a");
  parser.href = url;

  return parser.hostname;
};

const Img = styled.img`
  max-height: 1rem;
  margin-right: 0.4rem;
`;

interface Props {
  domain: string;
}

const Favicon = ({ domain }: Props): JSX.Element => (
  <Img
    src={`https://www.google.com/s2/favicons?sz=64&domain=${getDomain(domain)}`}
    alt={domain}
    width={18}
    height={18}
  />
);

export default Favicon;
