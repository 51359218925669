import { useEffect } from "react";
import { createPortal } from "react-dom";

interface Props {
  children: JSX.Element;
}

const modalRoot = document.getElementById("modal-root");

const ModalPortal = ({ children }: Props): JSX.Element => {
  const containerElement = document.createElement("div");

  useEffect(() => {
    modalRoot?.appendChild(containerElement);

    return () => {
      modalRoot?.removeChild(containerElement);
    };
  }, [containerElement]);

  return createPortal(children, containerElement);
};

export default ModalPortal;
