import styled from "styled-components";

const FloatingBottom = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  border-top: 1px solid rgb(91 119 187 / 20%);
  background: rgb(63 78 108 / 80%);
  color: white;

  @media print {
    display: none;
  }

  @media (min-width: 800px) {
    backdrop-filter: blur(0.6rem);
  }
`;

export default FloatingBottom;
