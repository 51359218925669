import { Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import button1 from "../../images/buttons/88x31/800x600.gif";
import button2 from "../../images/buttons/88x31/alienow.gif";
import button3 from "../../images/buttons/88x31/best_viewed_with_eyes.gif";
import button4 from "../../images/buttons/88x31/ie40.gif";
import juhqbutton from "../../images/buttons/88x31/juhqbutton.png";
import button5 from "../../images/buttons/88x31/netscape3a.gif";
import button6 from "../../images/buttons/88x31/tripod2.gif";
import button7 from "../../images/buttons/88x31/win95.gif";
import juhqbanner from "../../images/juhqbanner.png";
import juhqfire from "../../images/juhqfire.gif";
import ExternalLink from "../ExternalLink/ExternalLink";

const P = styled.p`
  color: white;
`;

interface Button {
  src: string;
  alt: string;
  link?: string;
}

const list: Button[] = [
  {
    src: button1,
    alt: "best viewed with 800x600",
    link: "800x600",
  },
  { src: button2, alt: "the truth", link: "/xfiles" },
  {
    src: button3,
    alt: "best viewed with eyes",
  },
  { src: button4, alt: "msie4.0" },
  {
    src: button5,
    alt: "best with netscape",
  },
  { src: button6, alt: "tripod" },
  { src: button7, alt: "made with windows" },
  { src: juhqbutton, alt: "juhqbest", link: "/juhq" },
];

// juhqbutton generated with http://webbuttonmaker.great-site.net
// other buttons from https://cyber.dabamos.de/88x31
const SmallButtonCollection = (): JSX.Element => (
  <>
    {list.map(({ src, alt, link }) => (
      <Fragment key={src}>
        {link && (
          <Link to={link}>
            <img src={src} alt={alt} width={88} height={31} />
          </Link>
        )}
        {!link && <img src={src} alt={alt} width={88} height={31} />}
      </Fragment>
    ))}

    <P>
      <small>
        88x31 buttons from{" "}
        <ExternalLink to="https://cyber.dabamos.de/88x31">
          https://cyber.dabamos.de/88x31
        </ExternalLink>
      </small>
    </P>
    <Link to="/banneri-vaihto">
      <img src={juhqbanner} alt="juhq" width={468} height={60} />
    </Link>
    <img src={juhqfire} alt="juhq" width={191} height={106} />
  </>
);

export default SmallButtonCollection;
