/* stylelint-disable declaration-colon-newline-after */

import throttle from "lodash/throttle";
import { memo, useState } from "react";
import styled from "styled-components";

import juhq from "../../images/juhq.jpeg";

interface LogoProps {
  coords: { left: number; top: number };
}

const Logo = styled.img`
  width: auto;
  height: 3rem;
  clip-path: ${({ coords }: LogoProps) => `path(
    "M${coords.left} ${coords.top}.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"
  )`};
  transform: scale(1.4);
`;

const CorporateLogo = (): JSX.Element => {
  const [coords, setCoords] = useState({ left: 35, top: 16 });

  return (
    <div
      onMouseMove={throttle((event) => {
        setCoords({
          left: coords.left + event.nativeEvent.movementX,
          top: coords.top + event.nativeEvent.movementY,
        });
      }, 100)}
    >
      <Logo src={juhq} alt="Company Logo" coords={coords} />
    </div>
  );
};

export default memo(CorporateLogo);
