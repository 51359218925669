const DeploymentLinks = (): JSX.Element => (
  <small>
    <ul>
      <li>
        <a href="/coverage">Coverage</a>
      </li>
      <li>
        <a href="/storybook">Storybook</a>
      </li>
      <li>
        <a href="/coverage/junit.html">junit</a>
      </li>
      <li>
        <a href="/coverage/bundle-tree.html">bundle tree</a>
      </li>
      <li>
        <a href="/coverage/jest_html_reporters.html">Jest html report</a>
      </li>
    </ul>
  </small>
);

export default DeploymentLinks;
