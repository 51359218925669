import styled from "styled-components";

// https://devfacts.com/media-queries-breakpoints-2021/
// @media (max-width: 575.98px) { ... }

// // Small devices (landscape phones, less than 768px)
// @media (max-width: 767.98px) { ... }

// // Medium devices (tablets, less than 992px)
// @media (max-width: 991.98px) { ... }

// // Large devices (desktops, less than 1200px)
// @media (max-width: 1199.98px) { ... }

const VisibleInDesktop = styled.span`
  && {
    @media (max-width: 1200px) {
      display: none;
    }
  }
`;

export default VisibleInDesktop;
