import { Suspense, lazy, memo, useState } from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../../pages/Home";
import Error404 from "../Errors/Error404/Error404";
import SuspenseFallback from "../SuspenseFallback/SuspenseFallback";
import routes, { Route as RouteType, gameRoutes } from "./routes";

const Blog = lazy(() => import("../Blog/Blog"));
const BlogError404 = lazy(() => import("../Blog/BlogError404/BlogError404"));
const BlogIndex = lazy(() => import("../Blog/BlogIndex/BlogIndex"));
const BlogPost = lazy(() => import("../Blog/BlogPost/BlogPost"));

const Games = lazy(() => import("../Games/Games"));
const GamesIndex = lazy(() => import("../Games/GamesIndex"));

const ChatGPT = lazy(() => import("../ChatGPT/ChatGPT"));
const ChatGPTIndex = lazy(() => import("../ChatGPT/ChatGPTIndex"));
const Pizza = lazy(() => import("../ChatGPT/Pizza/Pizza"));
const MonaLisa = lazy(() => import("../ChatGPT/MonaLisa/MonaLisa"));

const MyRoutes = (): JSX.Element => {
  const [listOfRoutes] = useState<RouteType[]>(routes());

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <Routes>
        <Route index element={<Home />} />
        {listOfRoutes.map(({ path, component }) => (
          <Route key={path} path={`/${path}`} element={component} />
        ))}
        <Route path="/blog" element={<Blog />}>
          <Route index element={<BlogIndex />} />
          <Route path=":postLink" element={<BlogPost />} />
          <Route path="*" element={<BlogError404 />} />
        </Route>
        <Route path="/games" element={<Games />}>
          <Route index element={<GamesIndex />} />
          {gameRoutes
            .filter(({ component }) => component)
            .map(({ path, component }) => (
              <Route key={path} path={path} element={component} />
            ))}
          <Route path="*" element={<Error404 />} />
        </Route>
        <Route path="/chat-gpt" element={<ChatGPT />}>
          <Route index element={<ChatGPTIndex />} />
          <Route path="pizza" element={<Pizza />} />
          <Route path="monalisa" element={<MonaLisa />} />
          <Route path="*" element={<Error404 />} />
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
};

export default memo(MyRoutes);
