import sortBy from "lodash/sortBy";
import { RefObject, SyntheticEvent } from "react";
import styled from "styled-components";

const Input = styled.input`
  width: 100%;
  color: #000;
`;

interface Value {
  path: string;
  title: string;
}

interface Props {
  values: Value[];
  onSelect: (val: string) => void;
  inputRef: RefObject<HTMLInputElement>;
}

const Datalist = ({
  values,
  onSelect,
  inputRef,
}: Props): JSX.Element | null => {
  const handleSelect = ({
    currentTarget,
  }: SyntheticEvent<HTMLInputElement, Event>) => {
    const { value: selection } = currentTarget;

    const found = values.find(({ path, title }) =>
      [`/${path}`, path, title]
        .map((v) => v.toLowerCase())
        .includes(selection.toLowerCase())
    );

    if (found) {
      onSelect(found.path);
    }
  };

  return (
    <>
      <Input
        ref={inputRef}
        autoComplete="off"
        list="values"
        id="select"
        name="select"
        onSelect={handleSelect}
      />
      <datalist id="values">
        {sortBy(values, "title").map(({ path, title }) => (
          <option value={`/${path}`} key={path}>
            {title}
          </option>
        ))}
      </datalist>
    </>
  );
};

export default Datalist;
