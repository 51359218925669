import { lazy } from "react";

import YearOclock from "../Time/YearOclock/YearOclock";

const AcademicQuotes = lazy(() => import("../../pages/AcademicQuotes"));
const Age = lazy(() => import("../../pages/Age"));
const AgeInWeekBlocks = lazy(() => import("../../pages/AgeInWeekBlocks"));
const ApplicationStorage = lazy(() => import("../../pages/ApplicationStorage"));
const AsciiJuhq = lazy(() => import("../../pages/AsciiJuhq"));
const Audiobooks = lazy(() => import("../../pages/Audiobooks"));
const Backlog = lazy(() => import("../../pages/Backlog"));
const BestViewedWith800x600 = lazy(
  () => import("../../pages/BestViewedWith800x600")
);
const BitcoinPrice = lazy(() => import("../../pages/BitcoinPrice"));
const BooksIHave = lazy(() => import("../../pages/BooksIHave"));
const BooksIHaveRead = lazy(() => import("../../pages/BooksIHaveRead"));
const Calculator = lazy(() => import("../../pages/Calculator"));
const CarsIveOwned = lazy(() => import("../../pages/CarsIveOwned"));
const CheckCreditCard = lazy(() => import("../../pages/CheckCreditCard"));
const CheckUsernamePassword = lazy(
  () => import("../../pages/CheckUsernamePassword")
);
const CitiesIveLivedIn = lazy(() => import("../../pages/CitiesIveLivedIn"));
const CitiesIveVisited = lazy(() => import("../../pages/CitiesIveVisited"));
const CodebaseSizeChart = lazy(() => import("../../pages/CodebaseSizeChart"));
const ColorGrid = lazy(() => import("../../pages/ColorGrid"));
const CountriesIveVisited = lazy(
  () => import("../../pages/CountriesIveVisited")
);
const Craiyon = lazy(() => import("../../pages/Craiyon"));
const DancingHamster = lazy(() => import("../../pages/DancingHamster"));
const Discord = lazy(() => import("../../pages/Discord"));
const DogecoinPrice = lazy(() => import("../../pages/DogecoinPrice"));
const DownloadAndroidVirus = lazy(
  () => import("../../pages/DownloadAndroidVirus")
);
const DownloadVirus = lazy(() => import("../../pages/DownloadVirus"));
const Education = lazy(() => import("../../pages/Education"));
const Error418 = lazy(() => import("../../pages/Error418"));
const Error500 = lazy(() => import("../../pages/Error500"));
const ExcuseGenerator = lazy(() => import("../../pages/ExcuseGenerator"));
const ExcuseHallOfFame = lazy(() => import("../../pages/ExcuseHallOfFame"));
const HowManyLegs = lazy(() => import("../../pages/HowManyLegs"));
const HTMLHellScoreList = lazy(() => import("../../pages/HTMLHellScoreList"));
const HumanNamedJuhq = lazy(() => import("../../pages/HumanNamedJuhq"));
const InternalLinks = lazy(() => import("../../pages/InternalLinks"));
const JuhQ = lazy(() => import("../../pages/JuhQ"));
const JuhQInBinary = lazy(() => import("../../pages/JuhQInBinary"));
const JuhqInHex = lazy(() => import("../../pages/JuhqInHex"));
const JuhQR = lazy(() => import("../../pages/JuhQR"));
const JuhQWeb = lazy(() => import("../../pages/JuhQWeb"));
const JuhQYeet = lazy(() => import("../../pages/JuhQYeet"));
const Links = lazy(() => import("../../pages/Links"));
const ListOf2048Games = lazy(() => import("../../pages/ListOf2048Games"));
const LogHouse = lazy(() => import("../../pages/LogHouse"));
const Mesmerizing = lazy(() => import("../../pages/Mesmerizing"));
const MuistaHakeaKahvia = lazy(() => import("../../pages/MuistaHakeaKahvia"));
const NeonColors = lazy(() => import("../../pages/NeonColors"));
const NumberIsJuhq = lazy(() => import("../../pages/NumberIsJuhq"));
const Panda = lazy(() => import("../../pages/Panda"));
const PersonalLinks = lazy(() => import("../../pages/PersonalLinks"));
const Photos = lazy(() => import("../../pages/Photos"));
const Playlist = lazy(() => import("../../pages/Playlist"));
const ProgressBarPage = lazy(() => import("../../pages/ProgressBarPage"));
const Puuh = lazy(() => import("../../pages/Puuh"));
const QuoteOfTheDay = lazy(() => import("../../pages/QuoteOfTheDay"));
const Radio = lazy(() => import("../../pages/Radio"));
const RedTextGreenBackground = lazy(
  () => import("../../pages/RedTextGreenBackground")
);
const RoundJuhQ = lazy(() => import("../../pages/RoundJuhQ"));
const Salary = lazy(() => import("../../pages/Salary"));
const Sandbox = lazy(() => import("../../pages/Sandbox"));
const Screenshot = lazy(() => import("../../pages/Screenshot"));
const ShouldI = lazy(() => import("../../pages/ShouldI"));
const Spotify = lazy(() => import("../../pages/Spotify"));
const Timeline = lazy(() => import("../../pages/Timeline"));
const Todo = lazy(() => import("../../pages/Todo"));
const Twitter = lazy(() => import("../../pages/Twitter"));
const Ufo = lazy(() => import("../../pages/Ufo"));
const Veikko = lazy(() => import("../../pages/Veikko"));
const VirtualHorses = lazy(() => import("../../pages/VirtualHorses"));
const Vito = lazy(() => import("../../pages/Vito"));
const WeatherIframe = lazy(() => import("../../pages/WeatherIframe"));
const WeekNumber = lazy(() => import("../../pages/WeekNumber"));
const XFiles = lazy(() => import("../../pages/XFiles"));
const YoutubeView = lazy(() => import("../../pages/YoutubeView"));
const BlazeOClock = lazy(() => import("../Time/BlazeOClock/BlazeOClock"));
const IsItFriday = lazy(() => import("../Time/IsItFriday/IsItFriday"));
const IsItFall = lazy(() => import("../Time/IsItFall/IsItFall"));
const IsItMyBirthday = lazy(
  () => import("../Time/IsItMyBirthday/IsItMyBirthday")
);
const IsItSaintUrhoDay = lazy(
  () => import("../Time/IsItSaintUrhoDay/IsItSaintUrhoDay")
);
const IsItSpring = lazy(() => import("../Time/IsItSpring/IsItSpring"));
const IsItSummer = lazy(() => import("../Time/IsItSummer/IsItSummer"));
const IsItTimeToParty = lazy(
  () => import("../Time/IsItTimeToParty/IsItTimeToParty")
);
const IsItWinter = lazy(() => import("../Time/IsItWinter/IsItWinter"));
const IsItYesterday = lazy(() => import("../Time/IsItYesterday/IsItYesterday"));
const LeetOClock = lazy(() => import("../Time/LeetOClock/LeetOClock"));
const PalindromeOClock = lazy(
  () => import("../Time/PalindromeOClock/PalindromeOClock")
);
const RepeatingOClock = lazy(
  () => import("../Time/RepeatingOClock/RepeatingOClock")
);
const Timestamp = lazy(() => import("../Time/Timestamp/Timestamp"));
const ClappingText = lazy(() => import("../Tools/ClappingText/ClappingText"));
const Consonants = lazy(() => import("../Tools/Consonants/Consonants"));
const ConvertImageToBase64 = lazy(
  () => import("../Tools/ConvertImageToBase64/ConvertImageToBase64")
);
const DistanceFunctions = lazy(
  () => import("../Tools/DistanceFunctions/DistanceFunctions")
);
const FindYoutubeUrls = lazy(
  () => import("../Tools/FindYoutubeUrls/FindYoutubeUrls")
);
const JsonResume = lazy(() => import("../Tools/JsonResume/JsonResume"));
const LispMath = lazy(() => import("../Tools/LispMath/LispMath"));
const ParseUrl = lazy(() => import("../Tools/ParseUrl/ParseUrl"));
const RgbToHsl = lazy(() => import("../Tools/RgbToHsl/RgbToHsl"));
const ScanRfid = lazy(() => import("../Tools/ScanRfid/ScanRfid"));
const SplitTextMiddle = lazy(
  () => import("../Tools/SplitTextMiddle/SplitTextMiddle")
);
const TimeFunctions = lazy(
  () => import("../Tools/TimeFunctions/TimeFunctions")
);
const Dinosaurs = lazy(() => import("../../pages/Dinosaurs"));
const HoverSound = lazy(() => import("../../pages/HoverSound"));
const PickEmoji = lazy(() => import("../../pages/PickEmoji"));
const AmerikanSuomalaiset = lazy(
  () => import("../../pages/AmerikanSuomalaiset")
);
const Antarctica = lazy(() => import("../../pages/Antarctica"));
const OhMy = lazy(() => import("../../pages/OhMy"));
const AmerikanSuomalaisetStarTrekissa = lazy(
  () => import("../../pages/AmerikanSuomalaisetStarTrekissa")
);
const Thesis = lazy(() => import("../../pages/Thesis"));
const HowOdd = lazy(() => import("../../pages/HowOdd"));
const TTauriainen = lazy(() => import("../../pages/TTauriainen"));
const AmerikanSuomenSanakirja = lazy(
  () => import("../../pages/AmerikanSuomenSanakirja")
);
const IUsedToBeWithIt = lazy(() => import("../../pages/IUsedToBeWithIt"));
const Zero = lazy(() => import("../../pages/Zero"));
const HelsinkiWebcams = lazy(() => import("../../pages/HelsinkiWebcams"));
const Tenx = lazy(() => import("../Games/Tenx/Tenx"));
const ComputerEducationResearch = lazy(
  () => import("../../pages/ComputerEducationResearch")
);
const SoundCloud = lazy(() => import("../../pages/SoundCloud"));
const Memory = lazy(() => import("../Games/Memory/Memory"));
const Unnamed = lazy(() => import("../Games/Unnamed/Unnamed"));
const TicTacToe = lazy(() => import("../Games/TicTacToe/TicTacToe"));
const GamesBacklog = lazy(() => import("../Games/Backlog"));
const Hangman = lazy(() => import("../Games/Hangman/Hangman"));
const AboutMe = lazy(() => import("../../pages/AboutMe"));
const WatermelonPizza = lazy(
  () => import("../ChatGPT/WatermelonPizza/WatermelonPizza")
);
const TenxTestimonials = lazy(() => import("../Games/Tenx/Testimonials"));
const RockPaperScissors = lazy(
  () => import("../Games/RockPaperScissors/RockPaperScissors")
);
const Guessing = lazy(() => import("../Games/Guessing/Guessing"));
const PasswordGenerator = lazy(() => import("../../pages/PasswordGenerator"));
const Jokes = lazy(() => import("../Jokes/Jokes"));
const BanneriVaihto = lazy(() => import("../BanneriVaihto/BanneriVaihto"));
const IsItEaster = lazy(() => import("../Time/IsItEaster/IsItEaster"));
const IsItBeerDay = lazy(() => import("../Time/IsItBeerDay/IsItBeerDay"));
const TimeIndex = lazy(() => import("../Time/TimeIndex/TimeIndex"));
const Thursday20th = lazy(() => import("../Time/Thursday20th/Thursday20th"));
const EveryCSSSelector = lazy(() => import("../../pages/EveryCSSSelector"));
const MixBlendMode = lazy(() => import("../../pages/MixBlendMode"));
const AsciiAnimations = lazy(() => import("../../pages/AsciiAnimations"));
const DisplayCursorTravel = lazy(
  () => import("../../pages/DisplayCursorTravel")
);
const EurovisionCountdown = lazy(
  () => import("../../pages/EurovisionCountdown")
);
const Eurovision = lazy(() => import("../../pages/Eurovision"));
const EurovisionBingo = lazy(() => import("../../pages/EurovisionBingo"));
const EyesNotWorking = lazy(() => import("../../pages/EyesNotWorking"));
const Guestbook = lazy(() => import("../../pages/Guestbook"));
const Best = lazy(() => import("../../pages/Best"));
const MoviesIveSeen = lazy(() => import("../../pages/MoviesIveSeen"));
const PhD = lazy(() => import("../../pages/PhD"));
const MyCoordinates = lazy(() => import("../../pages/MyCoordinates"));
const SocialMediaLinks = lazy(() => import("../../pages/SocialMediaLinks"));
const SolarSystem = lazy(() => import("../../pages/SolarSystem"));
const ChristmasCalendar = lazy(() => import("../../pages/ChristmasCalendar"));
const BooksGroupedByAuthor = lazy(() => import("../Books/Group/Author/Author"));
const ReactContext = lazy(() => import("../../pages/ReactContext"));
const UserAgent = lazy(() => import("../../pages/UserAgent"));
const BookStats = lazy(() => import("../../pages/BookStats"));
const GPLLicense = lazy(() => import("../../pages/GPLLicense"));
const DailyTimeOnSite = lazy(() => import("../../pages/DailyTimeOnSite"));
const LunchHistory = lazy(() => import("../../pages/LunchHistory"));
const ImageMap = lazy(() => import("../../pages/ImageMap"));
const DailyScrollTravel = lazy(() => import("../../pages/DailyScrollTravel"));
const AmerikanSuomalaisetYoutube = lazy(
  () => import("../../pages/AmerikanSuomalaisetYoutube")
);
const BirthdayBoy = lazy(() => import("../../pages/BirthdayBoy"));
const Studies = lazy(() => import("../../pages/Studies"));
const JyvaskylaUniversity = lazy(
  () => import("../../pages/JyvaskylaUniversity")
);
const Teaching = lazy(() => import("../../pages/Teaching"));

let baseCode = 0;
// eslint-disable-next-line no-plusplus
const nextCode = () => (baseCode++).toString().padStart(4, "0");

export interface Route {
  path: string;
  title: string;
  component: JSX.Element | null;
  hideFromModal?: boolean;
  numpadCode: string;
}

const extendRoutes = (routes: Omit<Route, "numpadCode">[]): Route[] =>
  routes.map((route) => ({ ...route, numpadCode: nextCode() }));

const routes: () => Omit<Route, "numpadCode">[] = () => [
  { path: "juhq", title: "JuhQ", component: <JuhQ /> },
  { path: "xfiles", title: "X-Files", component: <XFiles /> },
  { path: "ufo", title: "Ufo", component: <Ufo /> },
  { path: "weird", title: "Weird", component: <JuhQYeet /> },
  { path: "timeline", title: "Timeline", component: <Timeline /> },
  // 😻
  { path: "vito", title: "Vito", component: <Vito /> },
  { path: "veikko", title: "Veikko", component: <Veikko /> },
  { path: "backlog", title: "Backlog", component: <Backlog /> },
  { path: "panda", title: "Panda", component: <Panda /> },
  {
    path: "virtual-horses",
    title: "Virtual horses",
    component: <VirtualHorses />,
  },
  { path: "puuh", title: "puuh", component: <Puuh /> },
  { path: "audiobooks", title: "Audiobooks", component: <Audiobooks /> },
  { path: "neon-colors", title: "Neon colors", component: <NeonColors /> },
  { path: "color-grid", title: "ColorGrid", component: <ColorGrid /> },
  { path: "playlist/:name", title: "Playlist", component: <Playlist /> },
  { path: "radio", title: "Radio", component: <Radio /> },
  { path: "parse-url", title: "ParseUrl", component: <ParseUrl /> },
  { path: "round-juhq", title: "RoundJuhQ", component: <RoundJuhQ /> },
  { path: "photos", title: "Photos", component: <Photos /> },
  { path: "links", title: "Links", component: <Links /> },
  { path: "error-500", title: "Error 500", component: <Error500 /> },
  { path: "error-418", title: "Error418", component: <Error418 /> },
  {
    path: "number-is-juhq",
    title: "NumberIsJuhq",
    component: <NumberIsJuhq />,
  },
  {
    path: "academic-quotes",
    title: "Academic Quotes",
    component: <AcademicQuotes />,
  },
  {
    path: "time/is-it-1337-o-clock",
    title: "Is it 13:37 o'clock?",
    component: <LeetOClock />,
  },
  {
    path: "time/is-it-palindrome-o-clock",
    title: "Is it palindrome o'clock?",
    component: <PalindromeOClock />,
  },
  {
    path: "time/is-it-repeating-o-clock",
    title: "Is it repeating o'clock?",
    component: <RepeatingOClock />,
  },
  { path: "time/is-it-420", title: "Is it 420?", component: <BlazeOClock /> },
  {
    path: "excuse-generator",
    title: "Excuse generator",
    component: <ExcuseGenerator />,
  },
  {
    path: "youtube/:id",
    title: "Youtube View",
    component: <YoutubeView />,
    hideFromModal: true,
  },
  { path: "tools/rgb-to-hsl", title: "RGB to HSL", component: <RgbToHsl /> },
  { path: "tools/consonants", title: "Consonants", component: <Consonants /> },
  { path: "tools/scan-rfid", title: "ScanRfid", component: <ScanRfid /> },
  {
    path: "tools/timefunctions",
    title: "Time functions",
    component: <TimeFunctions />,
  },
  {
    path: "tools/distancefunctions",
    title: "Distance functions",
    component: <DistanceFunctions />,
  },
  { path: "tools/lisp-math", title: "Lisp math", component: <LispMath /> },
  {
    path: "tools/split-text-middle",
    title: "Split text from middle",
    component: <SplitTextMiddle />,
  },
  {
    path: "tools/generate-clapping-text",
    title: "Generate 👏 clapping 👏 text",
    component: <ClappingText />,
  },
  {
    path: "tools/find-youtube-urls",
    title: "Find YouTube urls",
    component: <FindYoutubeUrls />,
  },
  {
    path: "tools/convert-image-to-base64",
    title: "ConvertImageToBase64",
    component: <ConvertImageToBase64 />,
  },
  {
    path: "json/juha",
    title: "Json resume",
    component: <JsonResume />,
    hideFromModal: true,
  },
  {
    path: "dancing-hamster",
    title: "Dancing hamster",
    component: <DancingHamster />,
  },
  {
    path: "quote-of-the-day",
    title: "Quote of the day",
    component: <QuoteOfTheDay />,
  },
  {
    path: "personal-links",
    title: "Personal links",
    component: <PersonalLinks />,
  },
  {
    path: "download-virus",
    title: "DownloadVirus",
    component: <DownloadVirus />,
    hideFromModal: true,
  },
  {
    path: "download-android-virus",
    title: "Download Android Virus",
    component: <DownloadAndroidVirus />,
    hideFromModal: true,
  },
  {
    path: "dogecoin-price",
    title: "DogecoinPrice",
    component: <DogecoinPrice />,
  },
  { path: "bitcoin-price", title: "BitcoinPrice", component: <BitcoinPrice /> },
  { path: "loghouse", title: "LogHouse", component: <LogHouse /> },
  {
    path: "codebase-size-history",
    title: "Codebase size history",
    component: <CodebaseSizeChart />,
  },
  { path: "sandbox", title: "Sandbox", component: <Sandbox /> },
  { path: "weather", title: "Weather", component: <WeatherIframe /> },
  {
    path: "progressbars",
    title: "Progress Bars",
    component: <ProgressBarPage />,
  },
  {
    path: "is-it-yesterday",
    title: "Is it yesterday?",
    component: <IsItYesterday />,
  },
  { path: "is-it-winter", title: "Is it winter?", component: <IsItWinter /> },
  { path: "is-it-spring", title: "Is it spring?", component: <IsItSpring /> },
  { path: "is-it-summer", title: "Is it summer?", component: <IsItSummer /> },
  { path: "is-it-fall", title: "Is it fall?", component: <IsItFall /> },
  {
    path: "is-it-time-to-party",
    title: "Is it time to party?",
    component: <IsItTimeToParty />,
  },
  {
    path: "html-hell-scorelist",
    title: "HTML Hell Score List",
    component: <HTMLHellScoreList />,
  },
  {
    path: "cities-i-visited",
    title: "Cities I've visited",
    component: <CitiesIveVisited />,
  },
  {
    path: "cities-i-lived-in",
    title: "Cities I've lived in",
    component: <CitiesIveLivedIn />,
  },
  {
    path: "check-credit-card",
    title: "Check Credit Card",
    component: <CheckCreditCard />,
  },
  {
    path: "check-username-password",
    title: "Is your account compromised? Check now!",
    component: <CheckUsernamePassword />,
  },
  { path: "books-i-have", title: "Books I have", component: <BooksIHave /> },
  {
    path: "books-i-have-read",
    title: "Books I have read",
    component: <BooksIHaveRead />,
  },
  {
    path: "counties-ive-visited",
    title: "Countries I have visited",
    component: <CountriesIveVisited />,
  },
  {
    path: "cars-ive-owned",
    title: "Cars I've owned",
    component: <CarsIveOwned />,
  },
  {
    path: "800x600",
    title: "Best viewed with 800x600 resolution",
    component: <BestViewedWith800x600 />,
  },
  { path: "age", title: "Age", component: <Age /> },
  { path: "time/timestamp", title: "Timestamp", component: <Timestamp /> },
  {
    path: "age-in-week-blocks",
    title: "Age in weeks - cools blocks",
    component: <AgeInWeekBlocks />,
  },
  {
    path: "list-of-2048-games",
    title: "List of 2048 games",
    component: <ListOf2048Games />,
  },
  {
    path: "juhq-in-binary",
    title: "JuhQ in binary",
    component: <JuhQInBinary />,
  },
  {
    path: "how-many-legs-i-have",
    title: "How many legs do I have?",
    component: <HowManyLegs />,
  },
  { path: "should-i", title: "Should I?", component: <ShouldI /> },
  {
    path: "is-it-my-birthday",
    title: "Is it my birthday?",
    component: <IsItMyBirthday />,
  },
  { path: "week", title: "Week number", component: <WeekNumber /> },
  { path: "screenshot", title: "Screenshot", component: <Screenshot /> },
  { path: "education", title: "Education", component: <Education /> },
  { path: "salary", title: "Salary", component: <Salary /> },
  { path: "ascii-juhq", title: "Ascii JuhQ", component: <AsciiJuhq /> },
  { path: "todo", title: "// TODO", component: <Todo /> },
  { path: "juhq-in-hex", title: "JuhQ in Hex", component: <JuhqInHex /> },
  { path: "calculator", title: "Calculator", component: <Calculator /> },
  { path: "craiyon", title: "Craiyon", component: <Craiyon /> },
  {
    path: "excuse/hall-of-fame",
    title: "Excuse - Hall Of Fame",
    component: <ExcuseHallOfFame />,
  },
  { path: "mesmerizing", title: "Mesmerizing", component: <Mesmerizing /> },
  { path: "juhqr", title: "JuhQ QR", component: <JuhQR /> },
  {
    path: "red-text-green-background",
    title: "yep this is horrible",
    component: <RedTextGreenBackground />,
  },
  { path: "juhqweb", title: "JuhQWeb", component: <JuhQWeb /> },
  { path: "twitter", title: "Twitter", component: <Twitter /> },
  {
    path: "human-named-juhq",
    title: "Human named JuhQ",
    component: <HumanNamedJuhq />,
  },
  {
    path: "muista-hakea-kahvia",
    title: "Muista hakea kahvia",
    component: <MuistaHakeaKahvia />,
  },
  { path: "discord", title: "Discord", component: <Discord /> },
  { path: "spotify", title: "Spotify", component: <Spotify /> },
  {
    path: "time/is-it-friday",
    title: "Is It Friday",
    component: <IsItFriday />,
  },
  {
    path: "app-storage",
    title: "Application Storage",
    component: <ApplicationStorage />,
  },
  { path: "dinosaurs", title: "Dinosaurs", component: <Dinosaurs /> },
  { path: "hover-sound", title: "Hover Sound", component: <HoverSound /> },
  { path: "pick-emoji", title: "Pick Emoji", component: <PickEmoji /> },
  {
    path: "amerikansuomalaiset",
    title: "Amerikansuomalaiset",
    component: <AmerikanSuomalaiset />,
  },
  {
    path: "is-it-saint-urho-day",
    title: "Is it Saint Urho day?",
    component: <IsItSaintUrhoDay />,
  },
  { path: "antarctica", title: "Antarctica", component: <Antarctica /> },
  { path: "ohmy", title: "OhMy", component: <OhMy /> },
  {
    path: "amerikansuomalaiset/star-trek",
    title: "Amerikansuomalaiset Star Trekissä",
    component: <AmerikanSuomalaisetStarTrekissa />,
  },
  { path: "thesis", title: "Master's thesis", component: <Thesis /> },
  { path: "how-odd", title: "How Odd?", component: <HowOdd /> },
  {
    path: "blank-tauriainen",
    title: "<blank> Tauriainen",
    component: <TTauriainen />,
  },
  {
    path: "amerikansuomalaiset/sanakirja",
    title: "Amerikansuomen Sanakirja",
    component: <AmerikanSuomenSanakirja />,
  },
  {
    path: "i-used-to-be-with-it",
    title: "Then they changed what it is.",
    component: <IUsedToBeWithIt />,
  },
  { path: "0", title: "Zero", component: <Zero /> },
  { path: "year-o-clock", title: "Year O'clock", component: <YearOclock /> },
  {
    path: "helsinki/webcams",
    title: "Helsinki webcams",
    component: <HelsinkiWebcams />,
  },
  {
    path: "cer",
    title: "Computer Education Research",
    component: <ComputerEducationResearch />,
  },
  { path: "soundcloud", title: "SoundCloud", component: <SoundCloud /> },
  { path: "about-me", title: "About me", component: <AboutMe /> },
  {
    path: "recipes/watermelon-pizza",
    title: "Watermelon Pizza: What a delight",
    component: <WatermelonPizza />,
  },
  {
    path: "password-generator",
    title: "Password Generator",
    component: <PasswordGenerator />,
  },
  {
    path: "jokes",
    title: "Jokes",
    component: <Jokes />,
  },
  {
    path: "banneri-vaihto",
    title: "Banneri Vaihto",
    component: <BanneriVaihto />,
  },
  {
    path: "is-it-easter",
    title: "Is it Easter?",
    component: <IsItEaster />,
  },
  {
    path: "is-it-beer-day",
    title: "Is it Beer day?",
    component: <IsItBeerDay />,
  },
  {
    path: "time",
    title: "Time",
    component: <TimeIndex />,
  },
  {
    path: "time/thursday20th",
    title: "Thursday20th",
    component: <Thursday20th />,
  },
  {
    path: "every-css-selector",
    title: "Every CSS Selector, an attempt",
    component: <EveryCSSSelector />,
  },
  {
    path: "mix-blend-mode",
    title: "MixBlendMode",
    component: <MixBlendMode />,
  },
  {
    path: "ascii-animations",
    title: "AsciiAnimations",
    component: <AsciiAnimations />,
  },
  {
    path: "daily-cursor-travel",
    title: "Daily cursor travel",
    component: <DisplayCursorTravel />,
  },
  {
    path: "eurovision/countdown",
    title: "Eurovision Countdown",
    component: <EurovisionCountdown />,
  },
  { path: "eurovision", title: "Eurovision", component: <Eurovision /> },
  {
    path: "eurovision/bingo",
    title: "Eurovision Bingo",
    component: <EurovisionBingo />,
  },
  {
    path: "eyes-not-working",
    title: "EyesNotWorking",
    component: <EyesNotWorking />,
  },
  { path: "guestbook", title: "Guestbook", component: <Guestbook /> },
  { path: "best", title: "JuhQ Best", component: <Best /> },
  {
    path: "movies-ive-seen",
    title: "Movies I've Seen",
    component: <MoviesIveSeen />,
  },
  { path: "phd", title: "PhD", component: <PhD /> },
  {
    path: "my-coordinates",
    title: "MyCoordinates",
    component: <MyCoordinates />,
  },
  {
    path: "social-media-links",
    title: "Social Media Links",
    component: <SocialMediaLinks />,
  },
  { path: "solar-system", title: "SolarSystem", component: <SolarSystem /> },
  {
    path: "christmas-calendar",
    title: "Christmas Calendar",
    component: <ChristmasCalendar />,
  },
  {
    path: "books-i-have/group/author",
    title: "Books I have, grouped by author",
    component: <BooksGroupedByAuthor />,
  },
  { path: "react/context", title: "ReactContext", component: <ReactContext /> },
  { path: "useragent", title: "UserAgent", component: <UserAgent /> },
  {
    path: "books-i-have/stats",
    title: "Stats about books",
    component: <BookStats />,
  },
  { path: "gpl", title: "GPL", component: <GPLLicense /> },
  {
    path: "daily-time-on-site",
    title: "Daily Time On Site",
    component: <DailyTimeOnSite />,
  },
  {
    path: "lunch-history",
    title: "Lunch History",
    component: <LunchHistory />,
  },
  { path: "imagemap", title: "ImageMap", component: <ImageMap /> },
  {
    path: "daily-scroll-travel",
    title: "Daily Scroll",
    component: <DailyScrollTravel />,
  },
  {
    path: "amerikansuomalaiset/youtube",
    title: "Amerikansuomalaiset videot",
    component: <AmerikanSuomalaisetYoutube />,
  },
  { path: "birthdayboy", title: "Birthday Boy??", component: <BirthdayBoy /> },
  { path: "studies", title: "Studies", component: <Studies /> },
  {
    path: "studies/jyu",
    title: "University of Jyväskylä",
    component: <JyvaskylaUniversity />,
  },
  { path: "teaching", title: "Teaching", component: <Teaching /> },
];

const listOfRoutes = extendRoutes(routes());

export const gameRoutes = extendRoutes([
  {
    path: "",
    title: "Games",
    component: null,
  },
  {
    path: "backlog",
    title: "Games backlog",
    component: <GamesBacklog />,
  },
  {
    path: "10x",
    title: "10x",
    component: <Tenx />,
  },
  {
    path: "10x/testimonials",
    title: "10x Testimonials",
    component: <TenxTestimonials />,
  },
  {
    path: "memory",
    title: "Memory",
    component: <Memory />,
  },
  {
    path: "unnamed",
    title: "Unnamed",
    component: <Unnamed />,
  },
  {
    path: "tictactoe",
    title: "Tic Tac Toe",
    component: <TicTacToe />,
  },
  {
    path: "hangman",
    title: "Hangman",
    component: <Hangman />,
  },
  { path: "guessing", title: "Guessing", component: <Guessing /> },
  {
    path: "rock-paper-scissors",
    title: "RockPaperScissors",
    component: <RockPaperScissors />,
  },
]);

const includeInternal = (include = true): Route[] =>
  include
    ? listOfRoutes.concat(
        extendRoutes([
          {
            path: "internal-links",
            title: "Internal links",
            component: <InternalLinks />,
          },
        ])
      )
    : listOfRoutes;

export default includeInternal;
