interface Value {
  year: number;
  month: number;
  day: number;
}

const compareDates = (a: Value, b: Value) =>
  a.year === b.year && a.month === b.month && a.day === b.day;

export default compareDates;
