import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import { useEffect, useState } from "react";

interface Coordinates {
  x: number;
  y: number;
  clientX: number;
  clientY: number;
}

export interface CursorStateCoordinates {
  prev: Coordinates;
  current: Coordinates;
}

type Fn = typeof throttle | typeof debounce;

const useCursorCoordinates = (
  throttleFn: Fn = throttle
): CursorStateCoordinates => {
  const [coordinates, setCoordinates] = useState<CursorStateCoordinates>({
    prev: { x: 0, y: 0, clientX: 0, clientY: 0 },
    current: { x: 0, y: 0, clientX: 0, clientY: 0 },
  });

  const eventFunction = (event: MouseEvent) => {
    const { screenX: x, screenY: y, clientX, clientY } = event;

    setCoordinates(({ prev, current }) => ({
      prev: prev.x === 0 && prev.y === 0 ? { x, y, clientX, clientY } : current,
      current: { x, y, clientX, clientY },
    }));
  };

  useEffect(() => {
    const listener = throttleFn(eventFunction, 100);

    document.addEventListener("mousemove", listener);
    return () => {
      document.removeEventListener("mousemove", listener);
    };
  }, [throttleFn]);

  return coordinates;
};

export default useCursorCoordinates;
