import Modal from "../Modal/Modal";
import NumPad, { NumPadProps } from "../NumPad/NumPad";

interface Props extends NumPadProps {
  onClose: () => void;
}

const NumPadModal = ({ onMatch, onClose }: Props): JSX.Element => (
  <Modal open onClose={onClose}>
    <NumPad onMatch={onMatch} />
  </Modal>
);

export default NumPadModal;
