import { memo, useEffect, useState } from "react";

import useTimezone from "../../../hooks/timezone";
import Wikilink from "../../Wikilink/Wikilink";
import TimeSection from "../TimeSection";
import getTime from "./utils";

const YearOclock = (): JSX.Element => {
  const [time, setTime] = useState<string>("");
  const date = useTimezone();
  const [hourIsCurrentYear, setHourIsCurrentYear] = useState<boolean>(false);

  useEffect(() => {
    setTime(getTime(date));
    setHourIsCurrentYear(date.getFullYear() === new Date().getFullYear());
  }, [date]);

  return (
    <TimeSection>
      <Wikilink
        to={`https://en.wiktionary.org/wiki/${time}`}
        query={time}
        favicon={false}
      >
        {time}
      </Wikilink>
      {hourIsCurrentYear && <span> is the current year and hour</span>}
    </TimeSection>
  );
};

export default memo(YearOclock);
