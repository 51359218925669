import { memo, useEffect, useState } from "react";

import MillionthVisitor from "../MillionthVisitor/MillionthVisitor";
import Modal from "../Modal/Modal";

const MillionthVisitorPopup = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setModalOpen(Math.random() < 0.0001);
  }, []);

  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
      <MillionthVisitor />
    </Modal>
  );
};

export default memo(MillionthVisitorPopup);
