// from chat gpt

const matcher = /https?:\/\/([a-z]+)\.wikipedia\.org\/wiki\//;

export const isWikiLink = (url: string): string | undefined => {
  const match = url.match(matcher);

  return match?.[1];
};

export const getWikipediaLanguage = (url: string): string =>
  isWikiLink(url) || "en";

export const removeWikiUrl = (url: string): string =>
  url.replace(matcher, "").replace(/#[\w-_()%]+/, "");
