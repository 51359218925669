import pickAndPipe from "../../../utils/pickAndPipe";
import {
  Datalist,
  Range,
  Reset,
  SliderContainer,
} from "../BaseSlider/BaseSlider";

interface Props {
  words: string[];
  setWord: React.Dispatch<React.SetStateAction<string | null>>;
  fontStyling?: boolean;
}

const pickWord =
  (words: string[]) =>
  (n: number): string =>
    words[n];

const WordSlider = ({ words, setWord, fontStyling }: Props): JSX.Element => (
  <SliderContainer>
    <div>
      <Range
        type="range"
        step="1"
        min={0}
        max={words.length - 1}
        list="word-markers"
        // onChange={(w) => console.log("w", w.target.value)}
        onChange={pickAndPipe("target.value", Number, pickWord(words), setWord)}
      />
      <Datalist id="word-markers">
        {words.map((word) => (
          <option
            key={word}
            value={word}
            label={word}
            style={fontStyling ? { fontFamily: word } : {}}
          />
        ))}
      </Datalist>
    </div>
    <Reset type="reset" onClick={() => setWord(null)} value="X" />
  </SliderContainer>
);

WordSlider.defaultProps = {
  fontStyling: false,
};

export default WordSlider;
