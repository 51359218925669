import { memo, useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import styled from "styled-components";

import copyText from "../../utils/copytext";
import Modal from "../Modal/Modal";
import ShareLinks from "../ShareLinks/ShareLinks";

const Content = styled.div`
  color: #000;
  text-align: center;
`;

const CopyContainer = styled.div`
  margin: 1rem 0;
`;

interface Props {
  text?: string;
}

const ShareURL = ({ text }: Props): JSX.Element => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<string | null>(null);
  const copyRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (url) {
      copyText(url);
    }
  }, [url]);

  const handleClick = () => {
    setUrl(window.location.href);
    setModalOpen(true);
  };

  const handleCopy = () => {
    copyRef?.current?.select();
    copyText(url as string);
  };

  return (
    <>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Content>
          <p>URL has been copied to clipboard.</p>
          {url && (
            <>
              <QRCode value={url} />
              <CopyContainer>
                <input type="text" defaultValue={url} ref={copyRef} />
                <button type="button" onClick={handleCopy}>
                  Copy
                </button>
              </CopyContainer>
              <ShareLinks url={url} />
            </>
          )}
        </Content>
      </Modal>
      <button type="button" onClick={handleClick}>
        Share {text || "URL"}
      </button>
    </>
  );
};

ShareURL.defaultProps = { text: undefined };

export default memo(ShareURL);
