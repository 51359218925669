import { useEffect, useState } from "react";

import useScroll from "../../../hooks/scroll";

interface Props {
  onChange: (val: number) => void;
}

const ScrollTravel = ({ onChange }: Props): JSX.Element => {
  const coordinates = useScroll();
  const [distance, setDistance] = useState<number>(0);
  const [travel, setTravel] = useState<number>(0);

  useEffect(() => {
    const { prev, current } = coordinates;
    setDistance(Math.abs(prev - current));
  }, [coordinates]);

  useEffect(() => {
    setTravel((prev) => prev + distance);
  }, [distance]);

  useEffect(() => {
    if (travel > 0) {
      onChange(travel);
    }
  }, [onChange, travel]);

  return <>scrolled {travel.toFixed(2)} pixels</>;
};

export default ScrollTravel;
