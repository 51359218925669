import Snowfall from "react-snowfall";

import { isWinter } from "../../utils/seasons";

const Snow = (): JSX.Element | null => {
  if (!isWinter()) {
    return null;
  }

  return <Snowfall snowflakeCount={30} />;
};

export default Snow;
