import { memo } from "react";

import useValentinesDay from "../../hooks/days/valentinesday";

const ValentinesDay = (): null => {
  const isValentinesDay = useValentinesDay();

  if (isValentinesDay) {
    document.querySelector("html")?.classList.add("valentine");
  }

  return null;
};

export default memo(ValentinesDay);
