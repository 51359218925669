import { BrowserRouter } from "react-router-dom";

import AnimatePageTitle from "../AnimatePageTitle/AnimatePageTitle";
import CursorDirection from "../CursorDirection/CursorDirection";
import StoreCursorTravel from "../CursorStats/StoreCursorTravel/StoreCursorTravel";
import StoreDailyTimeOnSite from "../DailyTimeOnSite/StoreDailyTimeOnSite/StoreDailyTimeOnSite";
import Eyes from "../Eyes/Eyes";
import FloatingBottom from "../FloatingBottom/FloatingBottom";
import FloatingRadio from "../FloatingRadio/FloatingRadio";
import Footer from "../Footer/Footer";
import FullScreen from "../FullScreen/FullScreen";
import DisplayGarden from "../Garden/DisplayGarden";
import Header from "../Header/Header";
import MillionthVisitorPopup from "../MillionthVisitorPopup/MillionthVisitorPopup";
import NavigateModal from "../NavigateModal/NavigateModal";
import RotateFavicon from "../RotateFavicon/RotateFavicon";
import ScrollIndicator from "../Scroll/ScrollIndicator/ScrollIndicator";
import ScrollToTop from "../Scroll/ScrollToTop/ScrollToTop";
import StoreDailyScroll from "../Scroll/StoreDailyScroll/StoreDailyScroll";
import SmallButtonCollection from "../SmallButtonCollection/SmallButtonCollection";
import Snow from "../Snow/Snow";
import TravelStats from "../TravelStats/TravelStats";
import ValentinesDay from "../ValentinesDay/ValentinesDay";
import VisitorCounter from "../VisitorCounter/VisitorCounter";
import MyRoutes from "./MyRoutes";
import RouterHooks from "./RouterHooks/RouterHooks";

const Router = (): JSX.Element => (
  <BrowserRouter>
    <RouterHooks />
    <RotateFavicon />
    <Header />
    <AnimatePageTitle />
    <ScrollToTop />
    <NavigateModal />
    <FullScreen />
    <MyRoutes />
    <footer>
      <VisitorCounter />
      <SmallButtonCollection />
      <Eyes />
      <TravelStats />
      <CursorDirection />
    </footer>
    <Footer />
    <Snow />
    <FloatingBottom>
      <FloatingRadio />
    </FloatingBottom>
    <ValentinesDay />
    <MillionthVisitorPopup />
    <StoreCursorTravel />
    <StoreDailyTimeOnSite />
    <StoreDailyScroll />
    <ScrollIndicator />
    <DisplayGarden />
  </BrowserRouter>
);

export default Router;
