import { useState } from "react";
import styled from "styled-components";

import ButtonNoBorder from "../Buttons/ButtonNoBorder/ButtonNoBorder";
import YoutubePlayer from "../YoutubePlayer/YoutubePlayer";

const Button = styled(ButtonNoBorder)`
  padding: 1rem;
  border: none;
  background: transparent;
  color: white;
  cursor: pointer;
  font-size: 0.75rem;
`;

const Section = styled.section`
  display: none;
`;

const BackgroundMusic = (): JSX.Element => {
  const [play, setPlay] = useState<boolean>(true);

  return (
    <>
      <Button type="button" onClick={() => setPlay(false)}>
        Stop music
      </Button>
      <Section>{play && <YoutubePlayer id="xNjyG8S4_kI" autoplay />}</Section>
    </>
  );
};

export default BackgroundMusic;
