import { isSpring, isSummer } from "../../utils/seasons";
import Garden from "./Garden";

const DisplayGarden = (): JSX.Element | null => {
  if (!isSpring() && !isSummer()) {
    return null;
  }

  return <Garden />;
};

export default DisplayGarden;
