import throttle from "lodash/throttle";
import { memo, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import useCursorCoordinates, {
  CursorStateCoordinates,
} from "../../hooks/cursorcoordinates";

const size = 20;

const Eye = styled.div`
  position: relative;
  display: inline-block;
  width: ${size + 20}px;
  height: ${size + 20}px;
  background: #fff;
  border-radius: 50%;

  ::after {
    position: absolute;
    right: ${size}px;
    bottom: ${size - 4}px;
    width: ${size}px;
    height: ${size}px;
    background: ${({ color }: { color: string }) => color};
    border-radius: 50%;
    content: " ";
  }
`;

interface SingleEyeProps {
  coordinates: CursorStateCoordinates;
  color: string;
}

const SingleEyeBase = ({ coordinates, color }: SingleEyeProps): JSX.Element => {
  const [rotation, setRotation] = useState<number>(0);
  const eyeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current } = coordinates;
    const { clientX, clientY } = current;

    const eyeX = (eyeRef.current?.offsetLeft ?? 0) / 2;
    const eyeY = (eyeRef.current?.offsetTop ?? 0) / 2;
    const rad = Math.atan2(clientX - eyeX, clientY - eyeY);
    const rot = rad * (180 / Math.PI) * -1 + 180;

    setRotation(rot);
  }, [coordinates]);

  return (
    <Eye
      ref={eyeRef}
      color={color}
      style={{ transform: `rotate(${rotation}deg)` }}
    />
  );
};

const SingleEye = memo(SingleEyeBase);

const Eyes = (): JSX.Element => {
  const coordinates = useCursorCoordinates(throttle);

  return (
    <Link to="/eyes-not-working">
      <SingleEye coordinates={coordinates} color="#f14061" />
      <SingleEye coordinates={coordinates} color="#1361ef" />
    </Link>
  );
};

export default memo(Eyes);
