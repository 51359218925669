import { ReactNode } from "react";

import { sendExternalClickAnalytics } from "../../utils/analytics";
import Favicon from "../Favicon/Favicon";
import ExternalLinkIcon from "../Icons/ExternalLinkIcon";

export interface ExternalLinkProps {
  to: string;
  favicon?: boolean;
  className?: string;
  archive?: boolean;
  children?: ReactNode;
}

type LinkProps = Omit<ExternalLinkProps, "archive">;

const createWebArchiveUrl = (url: string): string =>
  `https://web.archive.org/web/*/${url}`;

const Link = ({ to, favicon, className, children }: LinkProps): JSX.Element => (
  <a
    href={to}
    target="_blank"
    rel="noreferrer nofollow noopener"
    className={className}
    onClick={() => {
      sendExternalClickAnalytics(to);
    }}
  >
    {favicon && <Favicon domain={to} />}
    {children}
  </a>
);

Link.defaultProps = {
  favicon: true,
  className: undefined,
  children: undefined,
};

const ExternalLink = ({
  to,
  favicon,
  className,
  archive,
  children,
}: ExternalLinkProps): JSX.Element => (
  <>
    <Link to={to} favicon={favicon} className={className}>
      {children}
    </Link>

    {archive && (
      <>
        {" "}
        -{" "}
        <Link
          to={createWebArchiveUrl(to)}
          favicon={false}
          className={className}
        >
          Archive <ExternalLinkIcon />
        </Link>
      </>
    )}
  </>
);

ExternalLink.defaultProps = {
  favicon: true,
  className: undefined,
  children: undefined,
  archive: false,
};

export default ExternalLink;
