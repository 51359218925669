/* eslint-disable no-plusplus */

import noop from "lodash/noop";
import throttle from "lodash/throttle";

import timezone from "../utils/timezone";

interface ParticleData {
  die: () => void;
  update: () => void;
  getLifeSpan: () => number;
}

interface XYCoordinates {
  x: number;
  y: number;
}

const styles = `
  position: absolute;
  display: block;
  pointer-events: none;
  z-index: 0;
  font-size: 16px;
  will-change: transform;
  transform: translate3d(0, 0, 0);
`;

const Particle = (x: number, y: number, character: string): ParticleData => {
  let lifeSpan = 120; // ms
  const random = Math.random();
  const velocity: XYCoordinates = {
    x: (random <= 0.5 ? -1 : 1) * (random / 2),
    y: 1,
  };
  const position: XYCoordinates = { x: x - 10, y: y - 20 };
  const element: HTMLSpanElement = document.createElement("span");
  element.innerHTML = character;
  element.setAttribute("style", styles);
  document.body.appendChild(element);

  const update = (): void => {
    position.x += velocity.x;
    position.y += velocity.y;
    lifeSpan--;

    element.style.transform = `scale(${lifeSpan / 120})`;
    element.style.top = `${position.y}px`;
    element.style.left = `${position.x}px`;
  };

  const die = (): void => {
    element.parentNode?.removeChild(element);
  };

  const getLifeSpan = (): number => lifeSpan;

  update();

  return { update, die, getLifeSpan };
};

const date = timezone();

class ParticleCharacter {
  private override: null | string = null;

  private isValentinesDay = false;

  private isChristmas = false;

  private isMyBirthday = false;

  private isHalloween = false;

  private isIndependenceDay = false;

  constructor() {
    this.isValentinesDay = date.getDate() === 14 && date.getMonth() === 1;
    this.isMyBirthday = date.getDate() === 21 && date.getMonth() === 2;
    this.isChristmas =
      (date.getDate() === 24 || date.getDate() === 25) &&
      date.getMonth() === 11;
    this.isHalloween = date.getDate() === 31 && date.getMonth() === 9;
    this.isIndependenceDay = date.getDate() === 6 && date.getMonth() === 11;
  }

  public setCharacter(character: null | string): void {
    this.override = character;
  }

  public getCharacter(): string {
    if (this.override) {
      return this.override;
    }

    if (this.isChristmas) {
      return "🎅";
    }

    if (this.isValentinesDay) {
      return "❤️";
    }

    if (this.isMyBirthday) {
      return "🎂";
    }

    if (this.isHalloween) {
      return "🎃";
    }

    if (this.isIndependenceDay) {
      return "🇫🇮";
    }

    if (date.getHours() >= 23 || date.getHours() <= 5) {
      return "🌙";
    }

    return "🌈";
  }
}

export const particleCharacter = new ParticleCharacter();

const cursor = (): (() => void) => {
  const emoji = document.getElementById("emoji");
  if (!emoji) {
    return noop;
  }

  let width = window.innerWidth;
  const cursorPosition = { x: width / 2, y: width / 2 };
  const particles: ParticleData[] = [];

  const addParticle = (x: number, y: number): void => {
    const character = particleCharacter.getCharacter();
    const { die, update, getLifeSpan } = Particle(x, y, character);
    particles.push({ update, die, getLifeSpan });
  };

  const updateParticles = (): void => {
    const particleCount = particles.length;
    let i = 0;
    while (i < particleCount) {
      particles[i++].update();
    }

    // Remove dead particles
    let k = particleCount;
    while (k--) {
      const particle = particles[k];
      if (particle.getLifeSpan() <= 0) {
        particle.die();
        particles.splice(k, 1);
      }
    }
  };

  const mouseMoveEvent = ({ pageX, pageY }: MouseEvent) => {
    cursorPosition.x = pageX;
    cursorPosition.y = pageY;

    addParticle(cursorPosition.x, cursorPosition.y);
    emoji.style.top = `${cursorPosition.y + 5}px`;
    emoji.style.left = `${cursorPosition.x + 5}px`;
  };

  const resizeEvent = () => {
    width = window.innerWidth;
  };

  const throttledEvent = throttle(mouseMoveEvent, 1000 / 50);
  document.addEventListener("mousemove", throttledEvent);
  window.addEventListener("resize", resizeEvent);

  let animationRequestId: number;
  const loop = () => {
    animationRequestId = requestAnimationFrame(loop);
    updateParticles();
  };

  loop();

  return () => {
    document.removeEventListener("mousemove", throttledEvent);
    window.removeEventListener("resize", resizeEvent);

    cancelAnimationFrame(animationRequestId);

    particles.forEach(({ die }) => die());
    particles.splice(0);
  };
};

export default cursor;
