import styled from "styled-components";

import SectionCenter from "../SectionCenter/SectionCenter";

const TimeSection = styled(SectionCenter)`
  min-height: 90vh;
  font-size: 7rem;
`;

export default TimeSection;
