import { createPortal } from "react-dom";

interface Props {
  children: React.ReactNode;
}

const Portal = ({ children }: Props) => {
  const portalRoot = document.getElementById("portal-root");

  if (!portalRoot) {
    const div = document.createElement("div");
    div.id = "portal-root";
    document.body.appendChild(div);
  }

  return createPortal(children, portalRoot || document.body);
};

export default Portal;
