import styled from "styled-components";

const Outer = styled.div`
  overflow: hidden;
`;

const Inner = styled.span`
  --move-initial: 100vw;
  --move-final: -120vw;

  display: flex;
  width: max-content;
  animation: marquee 25s linear infinite;
  transform: translate3d(var(--move-initial), 0, 0);

  @media screen and (prefers-reduced-motion) {
    animation: none;
  }

  @keyframes marquee {
    0% {
      transform: translate3d(var(--move-initial), 0, 0);
    }

    100% {
      transform: translate3d(var(--move-final), 0, 0);
    }
  }
`;

interface Props {
  children: JSX.Element | string;
}

const Marquee = ({ children }: Props): JSX.Element => (
  <Outer>
    <Inner>{children}</Inner>
  </Outer>
);

export default Marquee;
