/* stylelint-disable declaration-colon-newline-after */
import { memo } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import ExternalLink from "../ExternalLink/ExternalLink";

const linkCss = css`
  position: relative;
  display: flex;
  overflow: hidden;
  padding: 1rem 0.5rem;
  border-radius: 3px;
  text-shadow: 0 1px 0 #000, 0 -1px 0 #000, 1px 0 0 #000, -1px 0 0 #000;

  &::before {
    --size: 0;

    position: absolute;
    top: var(--y);
    left: var(--x);
    width: var(--size);
    height: var(--size);
    background: radial-gradient(
      circle closest-side,
      rgb(172 83 104 / 20%),
      transparent
    );
    content: "";
    transform: translate(-50%, -50%);
  }

  &:hover,
  &:focus {
    background: linear-gradient(0deg, #282c34, transparent);

    &::before {
      --size: 8rem;
    }
  }
`;

const MenuLink = styled(Link)`
  ${linkCss}
`;

const MenuLinkExternal = styled(ExternalLink)`
  ${linkCss}
`;

const handleMouseMove = ({
  currentTarget,
  clientX,
  clientY,
}: React.MouseEvent<HTMLElement>) => {
  const rect = currentTarget.getBoundingClientRect();
  const x = clientX - rect.left;
  const y = clientY - rect.top;
  currentTarget.style.setProperty("--x", `${x}px`);
  currentTarget.style.setProperty("--y", `${y}px`);
};

interface Props {
  to: string;
  name: string;
  external?: boolean;
}

const HeaderMenuLink = ({ to, name, external }: Props): JSX.Element => {
  if (external) {
    return (
      <MenuLinkExternal to={to} favicon={false}>
        {name}
      </MenuLinkExternal>
    );
  }

  return (
    <MenuLink to={to} onMouseMove={handleMouseMove}>
      {name}
    </MenuLink>
  );
};

HeaderMenuLink.defaultProps = {
  external: false,
};

export default memo(HeaderMenuLink);
