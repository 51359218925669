import { useEffect } from "react";

import useCursorTravel from "../../../hooks/cursortravel";

interface Props {
  onChange: (val: number) => void;
}

const CursorTravel = ({ onChange }: Props): JSX.Element => {
  const { travel } = useCursorTravel();

  useEffect(() => {
    if (travel > 0) {
      onChange(travel);
    }
  }, [onChange, travel]);

  return <>{travel.toFixed(2)} pixels</>;
};

export default CursorTravel;
