import { useEffect, useState } from "react";

import timezone from "../../utils/timezone";

const useValentinesDay = (): boolean => {
  const [yep, setYep] = useState<boolean>(false);

  useEffect(() => {
    const date = timezone();
    const mightBe = date.getDate() === 14 && date.getMonth() === 2 - 1;

    setYep(mightBe);
  }, []);

  return yep;
};

export default useValentinesDay;
