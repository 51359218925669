import sample from "lodash/sample";

import ExternalLink from "../ExternalLink/ExternalLink";
import gif1 from "./images/1739construct.gif";
import gif2 from "./images/AtAthensAegean7719Imagesconstruct.gif";
import gif3 from "./images/AtAthensAgora5789construct1.gif";
import gif4 from "./images/BaBajaCanyon9450construct4.gif";
import gif6 from "./images/CaCapeCanaveralHangar2256construction.gif";
import gif7 from "./images/HoHollywoodCinema6003graphicsunderconstruction.gif";
import gif11 from "./images/Labyrinth6941underconstruction1.gif";
import gif12 from "./images/MoMotorCity2429constructbar.gif";
import gif13 from "./images/NaNapaValleyVineyard9035construction.gif";
import gif14 from "./images/Omega7497construction.gif";
import gif16 from "./images/RaRainForestWetlands6001construction.gif";
import gif5 from "./images/basement_182cartman_underconstruction.gif";
import gif8 from "./images/jajasch2000construction.gif";
import gif9 from "./images/jvjvidekiorigimagesuconstruct.gif";
import gif10 from "./images/krkrusty390construction.gif";
import gif15 from "./images/pjpjdabomb_4u2cGIFSconstruction.gif";

const list = [
  gif1,
  gif2,
  gif3,
  gif4,
  gif5,
  gif6,
  gif7,
  gif8,
  gif9,
  gif10,
  gif11,
  gif12,
  gif13,
  gif14,
  gif15,
  gif16,
];

const UnderConstruction = (): JSX.Element => (
  <>
    <img src={sample(list)} alt="Under construction" />
    <p>
      <small>
        Gif from{" "}
        <ExternalLink to="http://textfiles.com/underconstruction/">
          http://textfiles.com/underconstruction/
        </ExternalLink>
      </small>
    </p>
  </>
);

export default UnderConstruction;
