import styled from "styled-components";

const Kbd = styled.kbd`
  display: inline-block;
  padding: 2px 4px;
  border: 1px solid #787878;
  background-color: #3e3e3e;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 20%), 0 2px 0 0 rgb(84 84 84 / 70%) inset;
  color: #fff;
  font-size: 0.85em;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
`;

export default Kbd;
