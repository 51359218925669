/* eslint-disable no-param-reassign */

import pickAndPipe from "../../utils/pickAndPipe";

const FontColorPicker = (): JSX.Element => {
  const setFontSize = (color: string) => {
    document.querySelectorAll("*").forEach((node) => {
      (node as HTMLBaseElement).style.color = color;
    });
  };

  return (
    <input type="color" onChange={pickAndPipe("target.value", setFontSize)} />
  );
};

export default FontColorPicker;
