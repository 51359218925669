/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";

import useScroll from "../../../hooks/scroll";
import useLocalStorage from "../../../hooks/storage/localstorage";
import compareDates from "../../CursorStats/StoreCursorTravel/utils";

interface Data {
  distance: number;
  year: number;
  month: number;
  day: number;
}

const StoreDailyScroll = (): null => {
  const [localValue, setLocalValue] = useLocalStorage<Data[]>(
    "scroll-daily-travel"
  );
  const coordinates = useScroll();
  const [distance, setDistance] = useState<number>(0);

  useEffect(() => {
    const { prev, current } = coordinates;
    setDistance(Math.abs(prev - current));
  }, [coordinates]);

  useEffect(() => {
    const dateObj = new Date();
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const obj = { distance, year, month, day };

    if (!localValue) {
      setLocalValue([obj]);
    } else {
      const value = localValue.find((v) =>
        compareDates(v, { year, month, day })
      );

      if (!value) {
        setLocalValue([...localValue, obj]);
      } else {
        setLocalValue(
          localValue.map((v) =>
            compareDates(v, { year, month, day })
              ? { ...v, distance: distance + v.distance }
              : v
          )
        );
      }
    }
  }, [distance]);

  return null;
};

export default StoreDailyScroll;
