import { useEffect, useState } from "react";

const useTimePassed = (intervalTime = 50): number => {
  const [initialTime, setInitialTime] = useState<number>(0);
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    setInitialTime(Date.now());
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((Date.now() - initialTime) / 1000);
    }, intervalTime);

    return () => {
      clearInterval(interval);
    };
  }, [initialTime, intervalTime]);

  return time;
};

export default useTimePassed;
