// github/followers/juhq?style=social
// github/issues/juhq/juhqfi_issues
// twitter/follow/juha_tauriainen?style=social
// youtube/channel/views/UCnZrO_AwUnhzh9p2QMtbkoA?style=social
// youtube/channel/subscribers/UCnZrO_AwUnhzh9p2QMtbkoA?style=social

// https://bitbucket.org/juhq_fi/juhq.fi-issues/src/master/
// bitbucket/issues/juhq_fi/juhq.fi-issues?logo=bitbucket

// https://securityheaders.com/
// security-headers?url=https%3A%2F%2Fjuhq.fi%2F

// website?url=https%3A%2F%2Fjuhq.fi%2F

// w3c-validation/default?targetUrl=https%3A%2F%2Fjuhq.fi

import ExternalLink from "../ExternalLink/ExternalLink";

interface Props {
  service: string;
  link?: string;
  alt?: string;
}

const ShieldIo = ({ service, link, alt }: Props): JSX.Element => {
  const src = `https://img.shields.io/${service}`;

  if (link) {
    return (
      <ExternalLink to={link} favicon={false}>
        <img src={src} alt={alt} />
      </ExternalLink>
    );
  }

  return <img src={src} alt={alt} />;
};

ShieldIo.defaultProps = { link: null, alt: "Some shielf" };

export default ShieldIo;
